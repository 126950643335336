import styled from 'styled-components';

export const StyledContainer = styled.div`
  /* Your NFTPanel styles here */
  width: 100%;

  .links {
    color: ${({ theme }) => theme.colors.dodoPurple};
    margin-bottom: 36px;
    font-weight: bold;
    font-size: 16px;

    a {
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &:visited {
        color: ${({ theme }) => theme.colors.dodoPurple};
      }
    }
  }
`;

export const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 70px 0;
  background-color: ${({ theme }) => theme.colors.whited2};
`;
