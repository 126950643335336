import React, { useState } from 'react';
import { INFT } from '@/types';
import { NFT } from '../NFT';
import { StyledContainer, StyledContent } from './Styles';

type Props = {
  nfts?: INFT[];
  activeNFT: INFT | undefined;
  handleSelect: (nft: INFT) => void;
};

const NFTSelector = ({ nfts = [], activeNFT, handleSelect }: Props) => (
  <StyledContainer>
    <div className="text">{`${nfts.length === 0 ? 'No DoDo Bird owned' : 'Choose your DoDo Bird'}`}</div>
    <StyledContent>
      {nfts.map((nft) => (
        <NFT nft={nft} isActive={!!activeNFT && nft.tokenId === activeNFT.tokenId} onSelect={handleSelect} />
      ))}
    </StyledContent>
  </StyledContainer>
);

export default NFTSelector;
