import React from 'react';
import keyBy from 'lodash/keyBy';
import { ProgressBar } from '@/components/ProgressBar';
import { goldCoinIcon } from '@/styles/imgMapping';
import { INFT } from '@/types';
import {
  StyledCoinStatus,
  StyledContainer,
  StyledContent,
  StyledLabelContainer,
  StyledNFTImageContainer,
  StyledProgressBarContainer,
  StyledTitleContainer,
} from './Styles';

type Props = {
  nft: INFT | undefined;
};

const NFTSummary = ({ nft }: Props) => {
  console.log('');
  return (
    <StyledContainer>
      <StyledNFTImageContainer>
        <img className="img" alt="NFT" src={nft?.image} />
      </StyledNFTImageContainer>
      <StyledContent>
        <StyledTitleContainer>
          <div className="name">{`DoDo Bird #${nft?.tokenId || 0}`}</div>
        </StyledTitleContainer>
        <StyledCoinStatus>
          <div className="status">
            <div className="bold">
              <a
                className="link purple"
                target="_blank"
                rel="noreferrer noopener"
                href="https://opensea.io/collection/dodobird-2"
              >
                OpenSea
              </a>
            </div>
          </div>
          <div className="status">
            <span>TokenID&nbsp;</span>
            <span className="bold">{`#${nft?.tokenId}`}</span>
          </div>
          <div className="status">
            <span>Miles&nbsp;</span>
            <span className="bold purple">{`${nft?.miles || 0}`}</span>
          </div>
          <div className="status">
            <span>Ranking&nbsp;</span>
            <span className="bold yellow">{`${nft?.ranking || 0}`}</span>
          </div>
          <div className="status">
            <span className="bold small">*Ranking will be automatically refreshed every UTC 00:00</span>
          </div>
        </StyledCoinStatus>
      </StyledContent>
    </StyledContainer>
  );
};

export default NFTSummary;
