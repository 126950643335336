import styled from 'styled-components';

export const StyledContainer = styled.div<{ isOpen: boolean; transitionTime: number }>`
  overflow: hidden;
  transition: height ${(props) => props.transitionTime}ms ease;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;
  padding: 24px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  // height: ${(props) => (props.isOpen ? 'auto' : 'fit-content')};

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 900;
    }
  }

  .arrow-icon {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  .content {
    p {
      line-height: 24px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
