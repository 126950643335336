import React, { useEffect, useState } from 'react';
import { INFT, IWorm } from '@/types';
import { WormCollection } from '../WormCollection';
import { WormFeed } from '../WormFeed';
import { StyledContainer } from './Styles';

const exampleWorms = [
  {
    name: 'DoDo Worm',
    tokenId: 1,
    description: 'A small worm that used to be a food source for the DoDo bird.',
    image: 'https://cdn.redreamer.review/DoDoBird/worms/DoDoBird_Worm.webp',
    attributes: [
      {
        trait_type: 'miles',
        value: 3,
        max_value: 10,
      },
    ],
    count: 2,
  },
  {
    name: 'DoDo Fat Worm',
    tokenId: 2,
    description: 'A fat worm that used to be a food source for the DoDo bird.',
    image: 'https://cdn.redreamer.review/DoDoBird/worms/DoDoBird_Fat-Worm.webp',
    attributes: [
      {
        trait_type: 'miles',
        value: 5,
        max_value: 10,
      },
    ],
    count: 0,
  },
  {
    name: 'DoDo Bug',
    tokenId: 3,
    description: 'A bug that used to be a food source for the DoDo bird.',
    image: 'https://cdn.redreamer.review/DoDoBird/worms/DoDoBird_Bug.webp',
    attributes: [
      {
        trait_type: 'miles',
        value: 10,
        max_value: 10,
      },
    ],
    count: 10,
  },
];

type Props = {
  nft: INFT | undefined;
  worms: IWorm[] | [];
  onSuccess?: () => void;
};

const WormPanel = ({ nft, worms = [], onSuccess = () => {} }: Props) => {
  const [selected, setSelected] = useState<number[]>([]);
  const handleSelect = (s: number[]) => {
    setSelected(s);
  };

  const handleSuccess = () => {
    setSelected([]);
    onSuccess();
  };

  return (
    <StyledContainer>
      <WormCollection worms={worms} onSelect={handleSelect} value={selected} />
      <WormFeed nft={nft} worms={worms} selected={selected} onSuccess={handleSuccess} />
    </StyledContainer>
  );
};

export default WormPanel;
