import React from 'react';
import { Button, ButtonType } from '@redreamerlab/uikit';
import { Row, StyledIconImg } from '@styles/common';
import { gridiconsNoticeIcon } from '@styles/imgMapping';
import { AppError } from '@/providers/pageProvider';
import { StyledMessage, StyledModal, StyledTitle } from './Styles';

type Props = {
  title: string;
  opened: boolean;
  onClose: () => void;
  error: AppError;
};

const ErrorModal = ({ title, opened, onClose, error }: Props) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <StyledModal hasBackDrop opened={opened} onClose={handleClose} width="450" disabledCloseButton>
      <Row justify="space-between" align="flex-start">
        <div>
          <StyledTitle>{title}</StyledTitle>
          <StyledMessage>{error?.message || error?.code || error?.error}</StyledMessage>
        </div>
        <StyledIconImg src={gridiconsNoticeIcon} alt="error" width="40px" />
      </Row>
      <Row justify="center">
        <Button buttonType={ButtonType.GENERAL} width="87px" onClick={handleClose} mt="32px">
          OK
        </Button>
      </Row>
    </StyledModal>
  );
};

export default ErrorModal;
