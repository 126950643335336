import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { downArrowIcon } from '@/styles/imgMapping';
import { Props } from './Props';
import { StyledContainer, StyledContent } from './Styles';

const Event = ({ title, status, startDate, endDate, link, color }: Props) => {
  const history = useHistory();

  const handleClick = () => {
    if (link) {
      if ((link || '').startsWith('http')) {
        window.open(link, '_blank', 'noopener');
      } else if ((link || '').startsWith('/')) {
        history.push(link);
      }
    }
  };
  const formatedStartDate = moment(startDate).format('YYYY/MM/DD HH:mm');
  const formatedEndDate = moment(endDate).format('YYYY/MM/DD HH:mm');
  return (
    <StyledContainer color={color} onClick={handleClick}>
      <StyledContent status={status}>
        <div className="title">{title}</div>
        {status && startDate && endDate && (
          <div className="status-date">
            {status && <div className="status">{status}</div>}
            {startDate && <div className="date">{`${formatedStartDate} - ${formatedEndDate}`}</div>}
          </div>
        )}
      </StyledContent>
      <img alt="arrow" className="arrow-icon" src={downArrowIcon} />
    </StyledContainer>
  );
};

export default Event;
