import React from 'react';
import { TabPanel } from 'react-tabs';
import { StyledTab, StyledTabList, StyledTabListContainer, StyledTabs } from './Styles';

type TabType = {
  key: string;
  label: string;
  Component: React.ElementType;
  disabled: boolean;
};

type Props = {
  tabList: TabType[];
  className?: string;
  onSelect?: (index: number, lastIndex: number, event: Event) => void;
  tabListSub?: string | React.ReactNode;
};

const WrappedTabs = ({ tabList, className, onSelect, tabListSub }: Props) => (
  <StyledTabs className={className} onSelect={onSelect}>
    <StyledTabListContainer>
      <StyledTabList>
        {tabList.map((tab) => (
          <StyledTab disabled={tab.disabled}>{tab.label}</StyledTab>
        ))}
      </StyledTabList>
      {tabListSub && tabListSub}
    </StyledTabListContainer>
    {tabList.map((tab) => {
      const { Component } = tab;
      return (
        <TabPanel>
          <Component />
        </TabPanel>
      );
    })}
  </StyledTabs>
);

export default WrappedTabs;
export type { TabType };
