/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Logger } from '@api/logger';
import { useGetDomin } from '@/api/getDomin';
import { useGetLeaderboard } from '@/api/getLeaderboard';
import { useGetTotalSupply } from '@/api/getTotalSupply';
import { SHOW_DODO_BIRD } from '@/config';
import { useResponsive } from '@/hooks/useResponsive';
import useApplicationContext from '@/providers/applicationContext';
import { AppError, usePageContext } from '@/providers/pageProvider';
import { dodobirdCircleImg, goldCoinIcon } from '@/styles/imgMapping';
import { truncateEthAddress } from '@/utils/truncate-eth-address';
import {
  StyledCongratz,
  StyledContainer,
  StyledInfo,
  StyledInfoContainer,
  StyledLeaderboard,
  StyledNotice,
} from './Styles';

const Rank = () => {
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [domin, setDomin] = useState(0);
  const [leaderboard, setLeaderboard] = useState<any>({});
  const { isMobile } = useResponsive();
  const { getLeaderboard } = useGetLeaderboard();
  const { getDomin } = useGetDomin();
  const { getTotalSupply } = useGetTotalSupply();
  const { updateError } = usePageContext();
  const { rankPurge } = useApplicationContext();

  const handleClick = () => {
    if (!isMobile) {
      return;
    }
    setOpen(!open);
  };

  useEffect(() => {
    getLeaderboard()
      .then((data) => {
        setLeaderboard(data || {});
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
        setLeaderboard({});
      });
    getDomin()
      .then((data) => {
        const amount = data?.amount || 0;
        setDomin(amount);
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
        setDomin(0);
      });
    getTotalSupply()
      .then((data) => {
        const { totalSupply } = data;
        setTotal(totalSupply);
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
      });
  }, [rankPurge]);

  return (
    <StyledContainer>
      <div className="wrap" onClick={handleClick}>
        <div className="collapse-content">
          <StyledInfoContainer>
            <StyledInfo>
              <div className="flex">
                <div className="title">{domin}</div>
                <img className="image" src={goldCoinIcon} alt="coin" />
                {(!isMobile || open) && <div className="sub-title">$DOMIN</div>}
              </div>
              {(!isMobile || open) && (
                <div className="description">
                  {250 - domin > 0 ? `${250 - domin} More to claim DoDo Bird, only 4800 winners` : 'Mint DoDo Bird now'}
                </div>
              )}
            </StyledInfo>
            <StyledInfo>
              <div className="flex">
                <div className="title">{`${SHOW_DODO_BIRD ? total : 200}/5000`}</div>
                <img className="image" src={dodobirdCircleImg} alt="dodobird" />
                {(!isMobile || open) && <div className="sub-title">DoDo Bird</div>}
              </div>
              {(!isMobile || open) && (
                <div className="description">had taken off, compete to win distribution of 6M $DOMIN</div>
              )}
            </StyledInfo>
          </StyledInfoContainer>
          {SHOW_DODO_BIRD && (
            <StyledCongratz>
              {!isMobile && <div className="title">Congratz on catching a DoDo Bird</div>}
              {!isMobile && (
                <div className="description">
                  A good bird is the one that brings you $$$, a fed bird is a good bird.
                </div>
              )}
              <div className="link">
                <a target="_blank" rel="noreferrer noopener" href="https://opensea.io/collection/dodobird-2">
                  Check my DoDo Bird
                </a>
              </div>
            </StyledCongratz>
          )}
        </div>
        {isMobile && <div className="arrow">{open ? '▲' : '▼'}</div>}
      </div>
      {(!isMobile || open) && (
        <StyledLeaderboard>
          <br />
          <hr />
          <br />
          <div className="title">Leaderboard</div>
          <StyledNotice>*Will be automatically refreshed every UTC 00:00</StyledNotice>
          <div className="ranks">
            <div className="yellow rank">
              <div className="order">{leaderboard?.user?.rank}</div>
              <div className="address">
                {leaderboard?.user?.image && <img className="image" src={leaderboard?.user?.image} alt="dodobird" />}
                <code>{truncateEthAddress(leaderboard?.user?.address || '')}</code>
              </div>
              <div className="domin">{`${leaderboard?.user?.domin}`}</div>
            </div>
            {(leaderboard?.leaderBoard || []).map((r: any) => (
              <div className="rank">
                <div className="order">{r.rank}</div>
                <div className="address">
                  {r.image && <img className="image" src={r.image} alt="dodobird" />}
                  <code>{truncateEthAddress(r.address || '')}</code>
                </div>
                <div className="domin">{`${r.domin}`}</div>
              </div>
            ))}
          </div>
        </StyledLeaderboard>
      )}
    </StyledContainer>
  );
};

function generateMockData() {
  const mockRanking = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 30; i++) {
    const mockEntry = {
      rank: i,
      address: `0x${Math.floor(Math.random() * 1e12)
        .toString(16)
        .padStart(12, '0')}`,
      domin: Math.floor(Math.random() * 10000000),
      hadDoDoBird: Math.random() < 0.5,
    };
    mockRanking.push(mockEntry);
  }
  return mockRanking;
}

const mockRanking = generateMockData();

export default Rank;
