import styled from 'styled-components';

export const StyledContainer = styled.div<{ isActive: boolean }>`
  flex-shrink: 0;
  border-radius: 18px;
  border: ${({ isActive }) => (isActive ? '10px solid white' : 'none')};
  overflow: hidden;
  box-shadow: ${({ isActive }) => (isActive ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' : 'none')};
  cursor: pointer;
  margin-bottom: 6px;

  &:not(:last-child) {
    margin-right: 18px;
  }

  .nft {
    height: 160px;
    width: 160px;
  }
`;
