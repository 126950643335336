/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import { foodIcon } from '@/styles/imgMapping';
import { IWorm } from '@/types';
import { Worm } from '../Worm';
import { StyledContainer, StyledNothingContainer } from './Styles';

type Props = {
  onSelect: (id: number[]) => void;
  worms: IWorm[];
  value: number[];
};

const WormCollection = ({ onSelect, worms, value = [] }: Props) => {
  const [selectedWorms, setSelectedWorms] = useState<Set<number>>(new Set(value));

  const handleWormSelect = (worm: IWorm) => {
    const { tokenId } = worm;
    const updatedSelectedWorms = new Set(selectedWorms);
    if (updatedSelectedWorms.has(tokenId)) {
      updatedSelectedWorms.delete(tokenId);
    } else {
      updatedSelectedWorms.add(tokenId);
    }
    setSelectedWorms(updatedSelectedWorms);
  };

  useEffect(() => {
    if (value.length === 0) {
      setSelectedWorms(new Set());
    }
  }, [value]);

  useEffect(() => {
    onSelect(Array.from(selectedWorms));
  }, [selectedWorms.size]);

  return (
    <StyledContainer>
      {worms.map(
        (worm) =>
          (worm?.balance || 0) > 0 && (
            <Worm key={worm.tokenId} selectedWorms={selectedWorms} worm={worm} onSelect={handleWormSelect} />
          ),
      )}
      {worms.length === 0 && (
        <StyledNothingContainer>
          <img className="icon" alt="food icon" src={foodIcon} />
          <div className="no">{"There's nothing here, yet."}</div>
          <div className="collect">Collect More Food</div>
        </StyledNothingContainer>
      )}
    </StyledContainer>
  );
};

export default WormCollection;
