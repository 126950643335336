import Binance from '../assets/Binance.svg';
import Discord from '../assets/Discord.svg';
import Ethereum from '../assets/Ethereum.svg';
import Facebook from '../assets/Facebook.svg';
import Instagram from '../assets/Instagram.svg';
import LINE from '../assets/LINE.svg';
import Linkedin from '../assets/Linkedin.svg';
import Medium from '../assets/Medium.svg';
import Polygon from '../assets/Polygon.svg';
import ThunderCore from '../assets/ThunderCore.svg';
import Twitter from '../assets/Twitter.svg';
import account from '../assets/account_circle.svg';
import WebsiteBg from '../assets/background.png';
import campaignDefault from '../assets/campaign_default.svg';
import campaignEnd from '../assets/campaign_end.svg';
import check from '../assets/check.gif';
import checkMark from '../assets/check_mark.svg';
import close from '../assets/close.svg';
import cloud1 from '../assets/cloud1.svg';
import cloud2 from '../assets/cloud2.svg';
import dashboard from '../assets/dashboard.svg';
import dodobirdCircle from '../assets/dodobird_circle.png';
import dodobirdDiagram from '../assets/dodobird_diagram.png';
import dominFooter from '../assets/domin_footer.svg';
import domin from '../assets/domin_logo.svg';
import dominNetwork from '../assets/domin_network.png';
import downArrow from '../assets/down_arrow.svg';
import food from '../assets/food.svg';
import ghostFrightened from '../assets/ghost_frightened.svg';
import goldCoin from '../assets/gold_coin.svg';
import gridiconsNotice from '../assets/gridicons_notice.svg';
import homeLogo from '../assets/home_logo.svg';
import iconDiscord from '../assets/icon_discord.svg';
import iconWeb from '../assets/icon_web.svg';
import iconX from '../assets/icon_x.svg';
import imageError from '../assets/img_error.svg';
import ghostParty from '../assets/img_ghost_party.svg';
import hamburger from '../assets/img_hamburger.svg';
import walletJoyId from '../assets/img_joyid.svg';
import imgLogin from '../assets/img_login.png';
import imgMint from '../assets/img_mint.gif';
import IconImage from '../assets/logo.svg';
import redreamer from '../assets/logo_icon.svg';
import logout from '../assets/logout.svg';
import mint from '../assets/mint_pic.png';
import successModal from '../assets/modal_success.svg';
import navAirdrop from '../assets/nav-airdrop.svg';
import navContribute from '../assets/nav-contribute.svg';
import navDodobird from '../assets/nav-dodobird.svg';
import refreshRounded from '../assets/refresh-rounded.svg';
import refresh from '../assets/refresh.svg';
import unbinding from '../assets/unbinding.svg';
import walletMetamask from '../assets/wallet-metamask.svg';
import walletConnect from '../assets/wallet_connect.svg';
import walletTT from '../assets/wallet_tt.svg';
import whiteTriangle from '../assets/white_triangle.svg';
import winner from '../assets/winner.png';
import zondiconsNetwork from '../assets/zondicons_network.svg';
import zondiconsNetworkPurple from '../assets/zondicons_network_purple.svg';

export const EthereumIcon = Ethereum;
export const ThunderCoreIcon = ThunderCore;
export const BinanceIcon = Binance;
export const PolygonIcon = Polygon;
export const unbindingIcon = unbinding;
export const zondiconsNetworkIcon = zondiconsNetwork;
export const zondiconsNetworkPurpleIcon = zondiconsNetworkPurple;
export const checkMarkIcon = checkMark;
export const closeIcon = close;
export const hamburgerIcon = hamburger;
export const campaignEndIcon = campaignEnd;
export const refreshIcon = refresh;
export const downArrowIcon = downArrow;
export const gridiconsNoticeIcon = gridiconsNotice;
export const refreshRoundedIcon = refreshRounded;
export const whiteTriangleIcon = whiteTriangle;
export const redreamerIcon = redreamer;
export const ghostFrightenedIcon = ghostFrightened;
export const campaignDefaultImg = campaignDefault;
export const walletMetamaskImg = walletMetamask;
export const walletConnectImg = walletConnect;
export const walletTTImg = walletTT;
export const walletJoyID = walletJoyId;
export const ghostPartyIcon = ghostParty;

export const LinkedinIcon = Linkedin;
export const FacebookIcon = Facebook;
export const DiscordIcon = Discord;
export const TwitterIcon = Twitter;
export const MediumIcon = Medium;
export const InstagramIcon = Instagram;
export const LINEIcon = LINE;

export const homeLogoImage = homeLogo;
export const LogoImage = IconImage;
export const dominLogo = domin;
export const imageErrorIcon = imageError;
export const WebsiteBgImage = WebsiteBg;
export const accountIcon = account;
export const mintImage = imgMint;
export const loginImage = imgLogin;
export const dominFooterImage = dominFooter;
export const webIcon = iconWeb;
export const xIcon = iconX;
export const discordIcon = iconDiscord;
export const goldCoinIcon = goldCoin;
export const foodIcon = food;
export const logoutIcon = logout;
export const dashboardIcon = dashboard;
export const cloud1Img = cloud1;
export const cloud2Img = cloud2;
export const winnerImg = winner;
export const dodobirdCircleImg = dodobirdCircle;
export const dodobirdDiagramImg = dodobirdDiagram;
export const dominNetworkImg = dominNetwork;
export const navAirdropIcon = navAirdrop;
export const navContributeIcon = navContribute;
export const navDodobirdIcon = navDodobird;
export const mintPic = mint;
export const checkImg = check;
export const successModalIcon = successModal;
