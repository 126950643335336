import React from 'react';
import { INFT } from '@/types';
import { StyledContainer } from './Styles';

type Props = {
  nft: INFT;
  isActive: boolean;
  onSelect: (event: any) => void;
};

const NFT = ({ nft, isActive = false, onSelect }: Props) => {
  const handleClick = () => {
    onSelect(nft);
  };
  return (
    <StyledContainer isActive={isActive} onClick={handleClick}>
      <img className="nft" alt="nft" src={nft?.image} />
    </StyledContainer>
  );
};

export default NFT;
