/* eslint-disable max-len */
import React, { useState } from 'react';
import { ButtonType } from '@redreamerlab/uikit';
import { useHistory } from 'react-router';
import { Button } from '@components/Button';
import { SHOW_DODO_BIRD } from '@/config';
import { useAuthContext } from '@/providers/authProvider';
import { checkImg, dodobirdCircleImg } from '@/styles/imgMapping';
import {
  Child1,
  Child2,
  Child3,
  StyledBar,
  StyledCheckImg,
  StyledColumn,
  StyledContainer,
  StyledDialog,
  StyledTab,
  StyledTabs,
  StyledVesting,
} from './Styles';

const Intro = () => {
  const [index, setIndex] = useState(1);
  const { authToken } = useAuthContext();
  const history = useHistory();

  const contentMap: any = {
    1: {
      column: [
        {
          title: 'Token Pool',
          content: <div className="purple bold">1M</div>,
        },
        {
          title: 'Vesting',
          content: (
            <div className="content">
              <div className="inline">10% TGE unlock,</div>
              <div>6 months linear vesting.</div>
            </div>
          ),
        },
        {
          title: 'Mechanics',
          content: (
            <div className="content">
              <ol>
                <li>Participate in airdrop campaigns to earn $DOMIN.</li>
                <li>Each campaigns have different supply & rules.</li>
              </ol>
            </div>
          ),
          flex: 2,
        },
      ],
      button: 'Airdrop',
      disabled: !authToken,
      handler: () => {
        history.push('/airdrop');
      },
    },
    2: {
      column: [
        {
          title: 'Token Pool',
          content: <div className="purple bold">3M</div>,
        },
        {
          title: 'Vesting',
          content: (
            <div className="content">
              <div className="inline">10% TGE unlock,</div>
              <div>6 months linear vesting.</div>
            </div>
          ),
        },
        {
          title: 'Mechanics',
          content: (
            <div className="content">
              <ol>
                <li>Participate in contribute activities like daily sign in, referral & redemption to earn $DOMIN.</li>
                <li>Contribute activities are FCFS and some of them have limitation in daily supply pool.</li>
              </ol>
            </div>
          ),
          flex: 2,
        },
      ],
      disabled: !authToken,
      button: 'Contribute',
      handler: () => {
        history.push('/contribute');
      },
    },
    3: {
      column: [
        {
          title: 'Token Pool',
          content: <div className="purple bold">6M</div>,
        },
        {
          title: 'Vesting',
          content: (
            <div className="content">
              <div className="inline purple">20%&nbsp;</div>
              <div className="inline">TGE unlock,</div>
              <div>6 months linear vesting.</div>
            </div>
          ),
        },
        {
          title: 'Mechanics',
          content: (
            <div className="content">
              <ol className="list">
                <li>Holders who reach the amount of 250 $DOMIN get the whitelist for free mint of DoDo Bird NFT.</li>
                <li>
                  DoDo Bird is FCFS and only have 5000 exclusive number, make sure to claim yours in lightning speed.
                </li>
                <li>
                  Holders of DoDo Bird who participate in Contribute activities will get a boost of 50x-100x $DOMIN in
                  return.
                </li>
              </ol>
            </div>
          ),
          flex: 2,
        },
      ],
      disabled: !SHOW_DODO_BIRD || !authToken,
      button: 'DoDoBird',
      handler: () => {
        history.push('/dodobird');
      },
    },
  };

  const handleChild1Click = () => {
    setIndex(1);
  };

  const handleChild2Click = () => {
    setIndex(2);
  };

  const handleChild3Click = () => {
    setIndex(3);
  };
  return (
    <StyledContainer>
      <h1>10M $DOMIN PRE-REGISTRATION</h1>
      <p>
        Mainnet $DOMIN is opened for claiming after TGE with vesting schedule. Check
        {' '}
        <a
          className="link"
          target="_blank"
          rel="noreferrer noopener"
          href="https://docs.domin.network/domin-network/tokenomics"
        >
          whitepaper
        </a>
        {' '}
        here.
      </p>
      <StyledBar>
        <Child1 onClick={handleChild1Click} index={index}>
          {index !== 1 && <StyledCheckImg className="image" src={checkImg} alt="check gif" />}
        </Child1>
        <Child2 onClick={handleChild2Click} index={index}>
          {index !== 2 && <StyledCheckImg className="image" src={checkImg} alt="check gif" />}
        </Child2>
        <Child3 onClick={handleChild3Click} index={index}>
          {index !== 3 && <StyledCheckImg className="image" src={checkImg} alt="check gif" />}
        </Child3>
      </StyledBar>
      <StyledTabs>
        <div className="first">
          <StyledTab active={index === 1} onClick={handleChild1Click}>
            Airdrop
          </StyledTab>
          <StyledTab active={index === 2} onClick={handleChild2Click}>
            ConTriBute
          </StyledTab>
        </div>
        <div className="second">
          <StyledTab active={index === 3} onClick={handleChild3Click}>
            <img className="image" src={dodobirdCircleImg} alt="DoDo Bird" />
            DoDo Bird
          </StyledTab>
        </div>
        <StyledDialog active={index === 3}>
          Showing our appreciation for the top 4800 most enthusiast DOMIN NETWORK supporters. &nbsp;
          <div className="purple">DoDo Bird holders</div>
          &nbsp;share&nbsp;
          <div className="purple">6M $DOMIN</div>
          &nbsp; with better vesting TGE unlock.
          <div className="big bold">Open to mint on 5th July</div>
        </StyledDialog>
      </StyledTabs>
      <StyledVesting>
        <div className="group">
          {(contentMap[index]?.column || []).map((column: any) => (
            <StyledColumn key={column?.title} flex={column?.flex}>
              <div className="title">{column?.title}</div>
              <div className="content">{column?.content}</div>
            </StyledColumn>
          ))}
        </div>
        <Button
          disabled={contentMap[index]?.disabled}
          onClick={contentMap[index]?.handler}
          type="button"
          buttonType={ButtonType.GENERAL}
        >
          {contentMap[index]?.button}
        </Button>
      </StyledVesting>
    </StyledContainer>
  );
};

export default Intro;
