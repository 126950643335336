import React, { useEffect, useState } from 'react';
import { ButtonType } from '@redreamerlab/uikit';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import { Logger } from '@api/logger';
import { useGetMint } from '@/api/getMint';
import { useGetTotalSupply } from '@/api/getTotalSupply';
import { usePostMint } from '@/api/postMint';
import { Button } from '@/components/Button';
import { SuccessModal } from '@/components/SuccessModal';
import { Marquee } from '@/pages/Home/Marquee';
import useApplicationContext from '@/providers/applicationContext';
import { AppError, usePageContext } from '@/providers/pageProvider';
import { mintPic } from '@/styles/imgMapping';
import { StyledContainer, StyledIntro, StyledIntro2, StyledLine, StyledMint } from './Styles';

const Mint: React.FC = () => {
  const [isMintable, setIsMintable] = useState(false);
  const [total, setTotal] = useState(0);
  const { popup, setPopup, handlePopupClose } = useApplicationContext();
  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId } = useWeb3ModalAccount();
  const { updateError } = usePageContext();
  const { getMint } = useGetMint();
  const { postMint } = usePostMint();
  const { getTotalSupply } = useGetTotalSupply();
  const handleButtonClick = async () => {
    if (!isMintable) return;

    try {
      if (chainId !== 137) {
        throw Error('Please switch network to Polygon.');
      }
      const params = await postMint();
      const { contractAddress, doDoBirdContractAddress, requester, amount, signature, abi } = params || {};
      if (walletProvider) {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        if (contractAddress) {
          const contract = new ethers.Contract(contractAddress, abi, provider.getSigner());
          await contract?.safeMint(requester, doDoBirdContractAddress, address, amount, signature);
          setPopup({
            opened: true,
            title: 'Congraduation!',
            description: 'Mint Do Do Bird Successfully',
          });
        }
      }
    } catch (e) {
      const error = e as AppError;
      Logger.error({ error });
      updateError(error);
    }
  };

  useEffect(() => {
    getMint()
      .then((data: any) => {
        const { isMintable } = data;
        setIsMintable(isMintable);
      })
      .catch(() => {
        setIsMintable(false);
      });
    getTotalSupply()
      .then((data) => {
        const { totalSupply } = data;
        setTotal(totalSupply);
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
      });
  }, []);

  return (
    <StyledContainer>
      <Marquee />
      <StyledMint>
        <div className="title">DoDo Bird NFT</div>
        <div className="minted">
          <span className="bold">{`${total}/${5000}`}</span>
          &nbsp;Have Taken Flights
        </div>
        <Button disabled={!isMintable} onClick={handleButtonClick} type="button" buttonType={ButtonType.GENERAL}>
          Mint
        </Button>
      </StyledMint>
      <StyledIntro>
        <div className="bold">
          Holders with more than 250 $DOMIN will get a whitelist spot for a free mint. DoDo Birds are available on a
          FCFS basis, with only 5000 exclusive spots. Make sure to claim yours quickly! Each wallet can claim only 1
          DoDo Bird.
        </div>
        <br />
        <div className="bold">Allocation of DoDo Bird NFT’s whitelist</div>
        <ul className="list">
          <li>First 4800 holders who accumulate 250 $DOMIN.</li>
          <li>200 reversed spots: 147 for strategy & marketing, 53 for the Boost Bird event winners</li>
        </ul>
        <div className="bold">Rewards</div>
        <ul className="list">
          <li>Unlock total supply of 6M of $DOMIN for contribution participation.</li>
          <li>General communities have 10% TGE unlock, DoDo Bird holders have 20%.</li>
          <li>Future early supporters rewards with partners.</li>
        </ul>
      </StyledIntro>
      <StyledLine />
      <StyledIntro2>
        <div className="title">
          Unlock total supply of 6M $DOMIN by claiming DoDo Bird & participating in contribution activities
        </div>
        <ol className="list">
          <li>
            Other than original $DOMIN, DoDoBird holders will get additional items (Food) by participating in&nbsp;
            contribution &nbsp;activity.
          </li>
          <li>Foods can be fed to your DoDo Bird to fly further in “miles”.</li>
          <li className="bold">A GOOD BIRD IS A BIRD THAT CAN FLY.</li>
          <li>The further your bird flies, the more $$$ can be brought back.</li>
          <li>
            The amount of rewards depends on your ranking and it will be calculated after the settlement. You will win
            50x-100x $DOMIN more than token earned from one day contribution.
          </li>
          <li>Trust us, birds experimentations have been conducted.</li>
        </ol>
        <img className="image" src={mintPic} alt="dodo bird flying" />
      </StyledIntro2>
      <SuccessModal
        title={popup?.title}
        opened={popup?.opened}
        onClose={handlePopupClose}
        message={popup?.description}
      />
    </StyledContainer>
  );
};

export default Mint;
