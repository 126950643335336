import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1140px;
  margin: auto;
  margin-top: 48px;
  margin-bottom: 48px;

  @media ${XS_QUERY} {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const StyledContent = styled.div`
  width: 100%;
  margin-left: 24px;
  min-width: 0;

  @media ${XS_QUERY} {
    margin-left: 0;
  }

  .content-title {
    color: white;
    font-size: 80px;
    font-weight: 900;
    text-shadow: black 3px 3px 4px;
    margin-bottom: 24px;

    @media ${XS_QUERY} {
      font-size: 40px;
    }
  }
`;

export const StyledSection = styled.div`
  font-size: 24px;
  margin: 24px 0;
  font-weight: 900;
`;

export const StyledProgress = styled.div`
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;
  border: 6px solid white;
  padding: 24px;
  background: ${({ theme }) => theme.colors.dodoPurple};
  border-radius: 32px;
  margin-bottom: 24px;

  .title {
    color: white;
    font-weight: 900;
    font-size: 20px;
  }
`;

export const StyledNotice = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 24px;
`;

export const StyledLabelContainer = styled.div<{ size?: number }>`
  display: flex;
  font-size: ${({ size = 20 }) => size}px;

  @media ${XS_QUERY} {
    font-size: 16px;
  }

  .value {
    font-weight: 700;
  }
  .max-value {
    margin-right: 6px;
  }
`;
