import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1140px;
  margin: auto;
  margin-top: 48px;
  margin-bottom: 48px;

  @media ${XS_QUERY} {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const StyledContent = styled.div`
  width: 100%;
  margin-left: 24px;
  min-width: 0;

  @media ${XS_QUERY} {
    margin-left: 0;
  }

  .content-title {
    color: white;
    font-size: 80px;
    font-weight: 900;
    text-shadow: black 3px 3px 4px;
    margin-bottom: 24px;

    @media ${XS_QUERY} {
      font-size: 40px;
    }
  }
`;
