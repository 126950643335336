import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${XS_QUERY} {
    flex-direction: column;
  }

  .first {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    margin-right: 24px;

    @media ${XS_QUERY} {
      margin: 0 0 24px 0;
    }

    .title {
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 48px;

      @media ${XS_QUERY} {
        font-size: 32px;
      }
    }

    .learn {
      background: ${({ theme }) => theme.colors.whitef2};
      padding: 24px;
      border-radius: 32px;
      width: 100%;

      .domin-network-img {
        width: 100%;
      }

      .description {
        margin-top: 12px;
        margin-bottom: 24px;
        font-size: 22px;
        font-weight: 900;

        @media ${XS_QUERY} {
          font-size: 16px;
        }
      }
    }
  }

  .image-container {
    flex: 1;
    width: 50%;

    @media ${XS_QUERY} {
      width: 100%;
    }

    .image {
      width: 100%;
    }
  }
`;
