import { Method } from '../../promise-request';

const caller = (data) => {
  const version = 'v1';
  const { address } = data;
  const url = `/${version}/getSignInMessage`;
  return ({
    method: Method.POST,
    url,
    data: { address },
  });
};

export default caller;
