import { useMediaQuery } from 'react-responsive';
import { SM_AND_DOWN_QUERY, SM_QUERY, XS_QUERY } from '@/breakpoints';

export const useResponsive = () => {
  const isMobile = useMediaQuery({ query: `${XS_QUERY}` });
  const isTablet = useMediaQuery({ query: `${SM_QUERY}` });
  const isSmallerThanTablet = useMediaQuery({ query: `${SM_AND_DOWN_QUERY}` });

  return {
    isMobile,
    isSmallerThanTablet,
    isTablet,
  };
};
