import React from 'react';
import Marquee from 'react-fast-marquee';
import { StyledContainer, StyledImage } from './Styles';
import nftImg1 from './assets/dodo_01.png';
import nftImg2 from './assets/dodo_02.png';
import nftImg3 from './assets/dodo_03.png';
import nftImg4 from './assets/dodo_04.png';
import nftImg5 from './assets/dodo_05.png';
import nftImg6 from './assets/dodo_06.png';

const imgTop = [
  () => <StyledImage src={nftImg1} />,
  () => <StyledImage src={nftImg2} />,
  () => <StyledImage src={nftImg3} />,
  () => <StyledImage src={nftImg1} />,
  () => <StyledImage src={nftImg2} />,
  () => <StyledImage src={nftImg3} />,
  () => <StyledImage src={nftImg1} />,
  () => <StyledImage src={nftImg2} />,
  () => <StyledImage src={nftImg3} />,
  () => <StyledImage src={nftImg1} />,
  () => <StyledImage src={nftImg2} />,
  () => <StyledImage src={nftImg3} />,
];
const imgBottom = [
  () => <StyledImage src={nftImg4} />,
  () => <StyledImage src={nftImg5} />,
  () => <StyledImage src={nftImg6} />,
  () => <StyledImage src={nftImg4} />,
  () => <StyledImage src={nftImg5} />,
  () => <StyledImage src={nftImg6} />,
  () => <StyledImage src={nftImg4} />,
  () => <StyledImage src={nftImg5} />,
  () => <StyledImage src={nftImg6} />,
  () => <StyledImage src={nftImg4} />,
  () => <StyledImage src={nftImg5} />,
  () => <StyledImage src={nftImg6} />,
];

const MarqueeCustomized = () => (
  <StyledContainer>
    <Marquee>
      {imgTop.map((I) => (
        <I />
      ))}
    </Marquee>
    <br />
    <Marquee direction="right">
      {imgBottom.map((I) => (
        <I />
      ))}
    </Marquee>
  </StyledContainer>
);

export default MarqueeCustomized;
