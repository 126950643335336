import React, { useState } from 'react';
import { Counter } from '@/components/Counter';
import { TabType, Tabs } from '@/components/Tabs';
import { INFT, IWorm } from '@/types';
import { ItemPanel } from '../ItemPanel';
import { WormPanel } from '../WormPanel';
import { StyledContainer, StyledWrappedTabs } from './Styles';

type Props = {
  nft: INFT | undefined;
  worms: IWorm[] | [];
  onSuccess?: () => void;
};

const CollectionPanel = ({ nft, worms = [], onSuccess = () => {} }: Props) => {
  const handleSuccess = () => {
    onSuccess();
  };
  return (
    <StyledContainer>
      <WormPanel onSuccess={handleSuccess} nft={nft} worms={worms} />
    </StyledContainer>
  );
};

export default CollectionPanel;
