import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { Tabs } from '@/components/Tabs';
import { StyledTab, StyledTabList, StyledTabs } from '@/components/Tabs/Styles';
import { CONTENT_WIDTH } from '@/pages/Page';

export const StyledContainer = styled.div`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  padding: 42px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;
  width: 100%;
  border-radius: 24px;

  @media ${XS_QUERY} {
    padding: 12px 20px;
    width: 100%;
    box-shadow: none;
    border-radius: none;
  }
`;

export const StyledWrappedTabs = styled(Tabs)`
  ${StyledTabList} {
    justify-content: center;
    width: fit-content;
    margin: auto;
    margin-bottom: 70px;

    @media ${XS_QUERY} {
      margin-bottom: 24px;
    }
  }
`;
