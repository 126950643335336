import { Button, ButtonType } from '@redreamerlab/uikit';
import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledMintButton = styled(Button)<{ disabled: boolean; buttonType: ButtonType }>`
  &&& {
    width: 100%;
    color: white;
    border-radius: 12px;
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.whited2 : theme.colors.dodoPurple)};
    padding: 10px 20px;
    border: none;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.whited2};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.dodoPurpleHover};
    }

    @media ${XS_QUERY} {
      font-size: 16px;
    }
  }
`;
