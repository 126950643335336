import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledImage = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 16px;
  margin-right: 12px;
`;
