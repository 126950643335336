import React, { useEffect, useState } from 'react';
import { ButtonType } from '@redreamerlab/uikit';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import keyBy from 'lodash/keyBy';
import { Logger } from '@api/logger';
import { Button } from '@components/Button';
import { useGetFeed } from '@/api/getFeed';
import { usePostFeed } from '@/api/postFeed';
import { Counter } from '@/components/Counter';
import { AppError, usePageContext } from '@/providers/pageProvider';
import { INFT, IWorm } from '@/types';
import { StyledContainer } from './Styles';

type Props = {
  nft: INFT | undefined;
  worms: IWorm[];
  selected: number[];
  onSuccess?: () => void;
};

const WormFeed = ({ nft, worms, selected = [], onSuccess = () => {} }: Props) => {
  const defaultCountMap = new Map(worms.map((worm) => [worm.tokenId, 0]));
  const [countMap, setCountMap] = useState(defaultCountMap);
  const wormMapping = keyBy(worms, 'tokenId');
  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();
  const { updateError } = usePageContext();
  const { getFeed, loadingGetFeed } = useGetFeed();
  const { postFeed, loadingPostFeed } = usePostFeed();
  const [transactionLoading, setTransactionLoading] = useState(false);

  const loading = transactionLoading || loadingGetFeed || loadingPostFeed;

  const sum = Array.from(countMap, ([name, value]) => value).reduce((acc, curr) => acc + curr, 0);
  const disabled = selected.length === 0 || sum === 0;

  const handleOnChange = (worm: IWorm) => (count: number) => {
    const { tokenId } = worm;
    const updatedCountMap = new Map(countMap);
    updatedCountMap.set(tokenId, count);
    setCountMap(updatedCountMap);
  };

  const handleButtonClick = async () => {
    setTransactionLoading(true);
    if (!nft?.tokenId) {
      return;
    }
    if (countMap.size <= 0) {
      return;
    }

    try {
      const params = await getFeed();
      let provider;
      let contract;
      const { wormContractAddress, operatorContractAddress, abi, isApprovedForAll } = params || {};
      if (walletProvider) {
        provider = new ethers.providers.Web3Provider(walletProvider);
      }
      if (wormContractAddress && operatorContractAddress && abi && provider) {
        contract = new ethers.Contract(wormContractAddress, abi, provider.getSigner());

        if (!isApprovedForAll) {
          await contract.setApprovalForAll(operatorContractAddress, true);
        }

        const r = await postFeed({
          birdTokenId: nft.tokenId,
          wormTokenIds: Array.from(countMap.keys()),
          wormAmounts: Array.from(countMap.values()),
        });

        if ((r || {}).operatorContractAddress) {
          const {
            operatorContractAddress,
            abi = ['function feed(address, bytes32, address, uint256[], uint256[], address, uint256, bytes)'],
            requester,
            redemptionId,
            wormContractAddress,
            wormTokenIds,
            wormAmounts,
            doDoBirdContractAddress,
            birdTokenId,
            signature,
          } = r || {};
          const operatorContract = new ethers.Contract(operatorContractAddress, abi, provider.getSigner());
          const result = await operatorContract.feed(
            requester,
            redemptionId,
            wormContractAddress,
            wormTokenIds,
            wormAmounts,
            doDoBirdContractAddress,
            birdTokenId,
            signature,
          );
        }

        onSuccess();
        setTransactionLoading(false);
      }
    } catch (e) {
      const error = e as AppError;
      Logger.error({ error });
      updateError(error);
      setTransactionLoading(false);
    }
  };

  useEffect(() => {
    if (selected.length === 0) {
      setCountMap(defaultCountMap);
    }
  }, [selected]);

  return (
    <StyledContainer>
      {selected.map((select) => {
        const worm = wormMapping[select];
        return (
          (worm?.balance || 0) > 0 && (
            <Counter
              key={worm.tokenId}
              img={worm?.image}
              value={countMap.get(worm.tokenId)}
              max={worm.balance}
              onChange={handleOnChange(worm)}
            />
          )
        );
      })}
      {selected.length <= 0 && <div className="content">Select Food to Feed (Gas Required)</div>}
      <Button
        loading={loading}
        onClick={handleButtonClick}
        disabled={disabled}
        type="button"
        buttonType={ButtonType.GENERAL}
      >
        Feed
      </Button>
    </StyledContainer>
  );
};

export default WormFeed;
