import React from 'react';
import { IWorm } from '@/types';
import { StyledContainer, StyledWormCount, StyledWormImage } from './Styles';

type Props = {
  worm: IWorm;
  selectedWorms: Set<number>;
  onSelect: (worm: IWorm) => void;
};

const Worm = ({ worm, selectedWorms, onSelect }: Props) => {
  const handleWormSelect = () => {
    onSelect(worm);
  };
  return (
    <StyledContainer onClick={handleWormSelect}>
      <StyledWormImage src={worm?.image} isSelected={selectedWorms.has(worm.tokenId)} />
      <StyledWormCount>{worm.balance}</StyledWormCount>
    </StyledContainer>
  );
};

export default Worm;
