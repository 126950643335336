import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
`;

export const StyledProgressBar = styled.div<{ height: number }>`
  position: relative;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;
  background-color: ${({ theme }) => theme.colors.whited2};
  border-radius: ${({ height = 50 }) => height / 2}px;
  height: ${({ height = 50 }) => height}px;
  overflow: hidden;
  font-size: 14px;
`;

export const StyledProgress = styled.div<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  height: 100%;
`;

export const StyledLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: black;
`;

export const StyledLevelLabel = styled.div`
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: black;
  width: 10%;
  min-width: 48px;
  height: 100%;
  padding-left: 12px;
  padding-right: 6px;
  transform: skewX(-20deg) translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .label {
    transform: skewX(20deg) translateX(5%);
    font-weight: 700;
  }
`;
