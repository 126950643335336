import React from 'react';
import { TabType } from '@/components/Tabs';
import { INFT } from '@/types';
import { Mint } from '../Mint';
import { NFTPanel } from '../NFTPanel';
import { StyledContainer, StyledWrappedTabs } from './Styles';

const NFTDetail = () => {
  const tabList: TabType[] = [
    {
      key: 'mint',
      label: 'Mint',
      Component: () => <Mint />,
      disabled: false,
    },
    {
      key: 'my_collections',
      label: 'My Collections',
      Component: () => <NFTPanel />,
      disabled: false,
    },
  ];

  return (
    <StyledContainer>
      <StyledWrappedTabs tabList={tabList} />
    </StyledContainer>
  );
};
export default NFTDetail;
