import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;

  @media ${XS_QUERY} {
  }
`;

export const StyledNothingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .icon {
    margin-bottom: 24px;
  }

  .no {
    color: ${({ theme }) => theme.colors.whited2};
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 24px;
  }

  .collect {
    font-size: 16px;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.dodoPurple};
  }
`;
