import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  display: flex;

  @media ${XS_QUERY} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledNFTImageContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 12px solid ${({ theme }) => theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 24px;

  @media ${XS_QUERY} {
    margin-bottom: 24px;
    margin-right: 0px;
  }

  .img {
    width: 100%;
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  font-weight:: 900;

  @media ${XS_QUERY} {
    flex-direction: column;
  }
  .name {
    font-size: 48px;
    font-size: 700;
    @media ${XS_QUERY} {
      margin-bottom: 12px;
    }
  }
  .btn-group {
    display: flex;

    .btn {
      font-size: 14px;
      color: white;
      padding: 6px;
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;
      border: 3px solid ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.dodoPurple};
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 12px;
      }
      &:hover {
        background-color: ${({ theme }) => theme.colors.dodoPurpleHover};
      }
    }
  }
`;

export const StyledCoinStatus = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media ${XS_QUERY} {
    align-items: center;
  }

  .yellow {
    color: ${({ theme }) => theme.colors.shineYellow};
  }

  .purple {
    color: ${({ theme }) => theme.colors.dodoPurple};
  }

  .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    width: 50%;

    .link {
      text-decoration: none;
    }

    @media ${XS_QUERY} {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 24px;

      @media ${XS_QUERY} {
        margin-right: 0;
        margin-bottom: 6px;
      }
    }

    .img {
      margin-right: 6px;
    }

    .bold {
      font-weight: 900;
      font-size: 24px;
    }

    .purple {
      color: ${({ theme }) => theme.colors.dodoPurple};
    }

    .small {
      font-size: 16px;
    }
  }
`;

export const StyledLabelContainer = styled.div<{ size?: number }>`
  display: flex;
  font-size: ${({ size = 20 }) => size}px;

  .value {
    font-weight: 700;
  }
  .max-value {
    margin-right: 6px;
  }
`;

export const StyledProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
