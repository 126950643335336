import styled from 'styled-components';
import { SM_AND_DOWN_QUERY } from '@/breakpoints';

const HEIGHT = 50;

export const StyledHeader = styled.header`
  padding-top: ${HEIGHT}px;
`;

export const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${HEIGHT}px;
  background-color: white;
  color: #b143ff;
  z-index: 5;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: auto;
  font-size: 24px;
  max-width: 1440px;
  padding: 0 24px;
`;

export const StyledImage = styled.img`
  height: 100%;
  cursor: pointer;
  padding: 10px;
`;

export const StyledGroup = styled.div<{ isSmallerThanTablet: boolean; open: boolean }>`
  display: flex;
  height: auto;
  position: ${({ isSmallerThanTablet, open }) => {
    if (isSmallerThanTablet) {
      return 'absolute';
    }
    return 'relative';
  }};
  flex-direction: ${({ isSmallerThanTablet }) => (isSmallerThanTablet ? 'column' : 'row')};
  justify-content: space-around;
  align-items: center;

  @media ${SM_AND_DOWN_QUERY} {
    padding: 24px;
    background-color: white;
    top: ${HEIGHT}px;
    width: 100vw;
    left: 0;
    overflow: hidden;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    height: ${({ open }) => (open ? 'auto' : '0')};
  }
`;

export const StyledItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.dodoPurple};
  margin-right: 24px;
  cursor: pointer;

  @media ${SM_AND_DOWN_QUERY} {
    margin-bottom: 24px;
    margin-right: 0px;
  }

  .icon {
    margin-right: 6px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonHover};
  }

  a {
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.colors.dodoPurple};
    }
    color: ${({ theme }) => theme.colors.dodoPurple};
  }
`;

export const StyledBackDrop = styled.div<{ hasBackDrop: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  opacity: ${({ hasBackDrop }) => (hasBackDrop ? 0.75 : 0)};
  z-index: 3;
  transition: opacity 0.3s;
`;

export const StyledDropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .content {
    margin-left: 6px;
  }
`;

export const StyledDropdownContent = styled.div`
  display: block;
  position: absolute;
  min-width: 160px;
  padding: 12px 16px;
  z-index: 1;
  background: white;
  border: 1px solid #ffffff;
  right: 0;
  top: 40px;

  @media ${SM_AND_DOWN_QUERY} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    right: auto;
    top: auto;
    border: none;
  }

  .item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.dodoPurple};
      background-color: ${({ theme }) => theme.colors.dodoPurpleHover};
    }

    &:not(:last-child) {
      margin-bottom: 12px;
      @media ${SM_AND_DOWN_QUERY} {
        margin-bottom: 0;
      }
    }

    &:before {
      display: flex;
      align-items: center;
      content: '';
      height: 30px;
      width: 30px;
      margin-right: 12px;
      background-position-x: 50%;
      background-position-y: 50%;
    }
  }

  .active {
    color: ${({ theme }) => theme.colors.dodoPurple};

    &:before {
      display: flex;
      align-items: center;
      content: '';
      height: 30px;
      width: 30px;
      margin-right: 12px;
      background-position-x: 50%;
      background-position-y: 50%;
    }
  }
`;
