import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  width: 400px;
  padding: 24px;
  border: 6px solid white;
  background: #9043ff;
  border-radius: 32px;
  background: rgba(144, 67, 255, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;

  .collapse-content {
    display: flex;
    flex-direction: column;

    @media ${XS_QUERY} {
    }
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    pointer: cursor;
  }

  @media ${XS_QUERY} {
    width: 100%;
    border-radius: 0px;
    padding: 6px;
    margin-bottom: 24px;
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${XS_QUERY} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const StyledInfo = styled.div`
  margin-bottom: 36px;
  @media ${XS_QUERY} {
    margin-bottom: 6px;
    margin-right: 24px;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;

    @media ${XS_QUERY} {
      margin-bottom: 6px;
    }

    .title {
      font-size: 64px;
      font-weight: 900;
      color: #ffc700;
      width: 100%;

      @media ${XS_QUERY} {
        font-size: 16px;
        width: fit-content;
      }
    }

    .sub-title {
      align-items: center;
      font-size: 32px;
      font-weight: 900;
      color: white;
      line-height: 36px;

      @media ${XS_QUERY} {
        font-size: 14px;
        width: 100%;
      }
    }

    .image {
      display: inline-block;
      height: 100%;
      width: 36px;
      height: 36px;
      margin-right: 12px;

      @media ${XS_QUERY} {
        width: 14px;
        height: 14px;
        margin-left: 6px;
        margin-right: 0px;
      }
    }
  }

  .description {
    color: white;
    font-size: 16px;
    @media ${XS_QUERY} {
      font-size: 8px;
    }
  }
`;

export const StyledCongratz = styled.div`
  background: linear-gradient(rgb(255, 199, 0), rgb(230, 152, 0), rgb(255, 214, 0));
  padding: 24px;
  border-radius: 32px;

  @media ${XS_QUERY} {
    padding: 3px;
    font-size: 12px;
  }

  .title {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 24px;
  }

  .description {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .link {
    text-align: center;

    a {
      text-decoration: underline;
      color: black;
    }
  }
`;

export const StyledLeaderboard = styled.div`
  .title {
    font-size: 32px;
    color: white;
    font-weight: 900;
    margin-bottom: 24px;
  }

  .ranks {
    .rank {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      color: white;
      font-weight: 900;
      margin-bottom: 12px;

      .order {
        flex: 1;
        text-align: right;
      }
      .address {
        flex: 5;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        text-align: right;
        align-items: center;

        .image {
          width: 24px;
          margin-right: 6px;
        }
      }
      .domin {
        flex: 4;
        text-align: right;
      }
    }
    .yellow {
      color: ${({ theme }) => theme.colors.shineYellow};
      font-weight: bold;
    }
  }
`;

export const StyledNotice = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 24px;
`;
