/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { downArrowIcon } from '@/styles/imgMapping';
import { StyledContainer } from './Styles';

type Props = {
  title: string;
  content: string | React.ReactNode;
  transitionTime?: number;
};

const Collapsible = ({ title, content, transitionTime = 200 }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledContainer isOpen={isOpen} onClick={toggleCollapsible} transitionTime={transitionTime}>
      <div className="title-container">
        <div className="title">{title}</div>
        <img alt="arrow" className="arrow-icon" src={downArrowIcon} />
      </div>
      {isOpen && (
        <div className="content">
          <p>{content}</p>
        </div>
      )}
    </StyledContainer>
  );
};

export default Collapsible;
