import styled from 'styled-components';
import { Status } from './Props';

export const StyledContainer = styled.div<{ color?: string }>`
  display: flex;
  border-radius: 32px;
  padding: 24px;
  border: 6px solid white;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;
  ${({ color }) =>
    color === 'YellowGradient'
      ? 'background: linear-gradient(rgb(255, 199, 0.7), rgb(230, 152, 0.7), rgb(255, 214, 0.7));'
      : 'background: rgba(255, 255, 255, 0.7);'};
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin-bottom: 24px;
  width: 100%;

  .arrow-icon {
    transform: rotate(-90deg);
    color: black;
  }
`;

export const StyledContent = styled.div<{ status?: Status }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  width: 100%;
  padding-right: 12px;

  .title {
    font-size: 16px;
    color: black;
    font-weight: 900;
    verticle
  }

  .status-date {
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 12px;

    .status {
      background-color: ${({ status, theme }) =>
        status === Status.ACTIVE ? theme.colors.dodoPurple : theme.colors.whited2};
      color: white;
      border: 2px solid black;
      border-radius: 12px;
      padding: 6px;
      margin-right: 12px;
    }
  }
`;
