import { Tab, TabList, Tabs } from 'react-tabs';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  color: black;
  width: 100%;
`;

export const StyledTabListContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTabList = styled(TabList)`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color ${({ theme }) => theme.colors.whitef2};
  font-size: 14px;
  padding: 12px 32px;
`;

export const StyledTab = styled(Tab)`
  padding: 24px;
  cursor: pointer;
  color: black;
  font-weight: 700;
  padding: 12px 24px;
  border-radius: 12px;

  &.react-tabs__tab--selected {
    color: white;
    background-color: ${({ theme }) => theme.colors.dodoPurple};
  }

  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.colors.dodoPurpleHover};
  }

  &:not(:last-child) {
    margin-right: 24px;
  }
`;
