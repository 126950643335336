import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { CONTENT_WIDTH } from '@/pages/Page';

export const StyledContainer = styled.div<{ transparent?: boolean }>`
  background: ${({ transparent = false }) => (transparent ? 'rgba(255, 255, 255, 0.7)' : '#fff')};
  backdrop-filter: blur(10px);
  padding: 42px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;
  width: ${CONTENT_WIDTH}px;
  margin: 24px auto;
  border-radius: 24px;

  @media ${XS_QUERY} {
    padding: 12px 20px;
    width: 100%;
    border-radius: none;
  }
`;
