import { Modal } from '@redreamerlab/uikit';
import styled from 'styled-components';
import { fontStyle } from '@styles/common';

export const StyledTitle = styled.div`
  ${fontStyle('24px', '600')};
  font-family: 'Poppins', sans-serif;
`;

export const StyledMessage = styled.div`
  ${fontStyle('16px', '700')};
  margin-top: 8px;
  word-break: break-word;
  font-family: 'Poppins', sans-serif;
`;

export const StyledModal = styled(Modal)`
  & > div:last-child {
    border: solid 5px ${({ theme }) => theme.colors.brightDream};
    padding: 48px;
  }
`;
