import React from 'react';
import styled from 'styled-components';
import { closeIcon, hamburgerIcon } from '@/styles/imgMapping';

const StyledContainer = styled.div`
  width: 18px;
  height: 18px;
`;

type Props = {
  open: boolean;
  onClick: () => void;
};

export const ToggleIcon = ({ open, onClick }: Props) => (
  <StyledContainer onClick={onClick}>
    {open ? <img alt="close menu" src={closeIcon} /> : <img alt="open menu" src={hamburgerIcon} />}
  </StyledContainer>
);
