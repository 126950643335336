import React, { useState } from 'react';
import { ButtonType } from '@redreamerlab/uikit';
import { useDisconnect, useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { Logger } from '@api/logger';
import { Button } from '@components/Button';
import { usePostReferralCode } from '@/api/postReferralCode';
import { ErrorModal } from '@/components/ErrorModal';
import { SectionCard } from '@/components/SectionCard';
import { SuccessModal } from '@/components/SuccessModal';
import Page from '@/pages/Page';
import useApplicationContext from '@/providers/applicationContext';
import { useAuthContext } from '@/providers/authProvider';
import { AppError, usePageContext } from '@/providers/pageProvider';
import { logoutIcon, winnerImg } from '@/styles/imgMapping';
import { truncateEthAddress } from '@/utils/truncate-eth-address';
import { Collapsible } from '../Home/Collapsible';
import { faq } from '../Home/faq';
import { Diagram } from './Diagram';
import { Intro } from './Intro';
import { StyledButtonGroup, StyledContainer, StyledFaqContainer } from './Styles';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC6K_1unCVyQxRWJtgKdop6VEiZz66HYOQ',
  authDomain: 'dodo-bird-nft.firebaseapp.com',
  projectId: 'dodo-bird-nft',
  storageBucket: 'dodo-bird-nft.appspot.com',
  messagingSenderId: '1057353115307',
  appId: '1:1057353115307:web:b63cffa980e2f1f18cf9ee',
  measurementId: 'G-CJR7TP75L7',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const provider = new GoogleAuthProvider();

const auth = getAuth();

const HomeNew = () => {
  const { popup, setPopup, handlePopupClose, referralCode, setReferralCode, appError, setAppError } =
    useApplicationContext();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const truncatedAddress = truncateEthAddress(address || '');
  const { setGoogleToken, googleToken, authToken, resetAuth } = useAuthContext();
  const { postReferralCode } = usePostReferralCode();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReferralCode(event.target.value || '');
  };

  const handleSendReferralCode = () => {
    if (referralCode) {
      postReferralCode({ code: referralCode }).catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        console.log(error);
        setAppError(error);
      });
    }
  };

  const handleLogout = () => {
    resetAuth();
    disconnect();
  };

  const handleGoogleConnect = () => {
    if (googleToken) {
      resetAuth();
      disconnect();
      return;
    }

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken || '';
        const googleToken = await result.user?.getIdToken();
        setGoogleToken(googleToken);
        // The signed-in user info.
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error?.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log(error);
      });
  };
  const handleWalletConnect = () => {
    if (isConnected) {
      handleLogout();
    } else {
      open();
    }
  };

  const getGoogleButtonName = () => {
    if (authToken) {
      return 'Verified';
    }

    if (googleToken) {
      return 'Log out';
    }
    return 'Google';
  };

  return (
    <Page contentWidth="100%">
      <>
        <SectionCard transparent>
          <StyledContainer>
            <img className="image" alt="winner" src={winnerImg} />
            <StyledButtonGroup>
              <div className="btn-container">
                <div className="label">1. Verify by login with google</div>
                <Button
                  disabled={authToken}
                  onClick={handleGoogleConnect}
                  type="button"
                  buttonType={ButtonType.GENERAL}
                >
                  {getGoogleButtonName()}
                </Button>
              </div>
              <div className="btn-container">
                <div className="label">2. Wallet Connect</div>
                <div className="bar">
                  <p>
                    Please connect with your same wallet in&nbsp;
                    <a target="_blank" rel="noreferrer noopener" href="https://app.galxe.com/">
                      Galxe
                    </a>
                    &nbsp;for rewards.
                  </p>
                </div>
                <Button
                  onClick={handleWalletConnect}
                  type="button"
                  buttonType={isConnected ? ButtonType.OUTLINE : ButtonType.GENERAL}
                >
                  {truncatedAddress && <img className="icon" alt="account icon" src={logoutIcon} />}
                  {`${truncatedAddress || 'Connect'}`}
                </Button>
              </div>
              <div className="btn-container">
                <div className="label">3. Referral Code</div>
                <div className="input-container">
                  <input
                    className="input"
                    value={referralCode}
                    onChange={handleInputChange}
                    placeholder="Referral Code (Optional)"
                  />
                  <Button
                    disabled={!referralCode}
                    onClick={handleSendReferralCode}
                    type="button"
                    buttonType={ButtonType.GENERAL}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </StyledButtonGroup>
          </StyledContainer>
        </SectionCard>
        <SectionCard>
          <Intro />
        </SectionCard>
        <SectionCard>
          <Diagram />
        </SectionCard>
        <StyledFaqContainer>
          <div className="paragraph">FAQ</div>
          {faq.map((f) => (
            <Collapsible key={f.id} title={f.title} content={f.content} />
          ))}
        </StyledFaqContainer>
        <br />
        <br />
        <ErrorModal
          title="Error"
          opened={!!appError}
          onClose={() => setAppError(undefined)}
          error={appError as AppError}
        />
        <SuccessModal
          title={popup?.title}
          opened={popup?.opened}
          onClose={handlePopupClose}
          message={popup?.description}
        />
      </>
    </Page>
  );
};

export default HomeNew;
