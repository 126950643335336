import React, { useEffect, useState } from 'react';
import { StyledCircle, StyledContainer, StyledImage, StyledLabel } from './Styles';

type Props = {
  img?: string;
  min?: number;
  max?: number;
  value?: number;
  label?: string;
  onChange: (value: number) => void;
};

const Counter = ({ img, min = 0, max = 1, value = 0, onChange, label = '' }: Props) => {
  const [count, setCount] = useState(value);

  const handleMinusClick = () => {
    if (count <= min) {
      return;
    }
    setCount((prev) => prev - 1);
  };

  const handlePluseClick = () => {
    if (count >= max) {
      return;
    }
    setCount((prev) => prev + 1);
  };

  useEffect(() => {
    onChange(count);
  }, [count]);

  return (
    <StyledContainer>
      <StyledImage alt="bug" src={img} />
      <StyledCircle disabled={count <= min} onClick={handleMinusClick}>
        -
      </StyledCircle>
      <StyledLabel>{`${count}/${max} ${label}`}</StyledLabel>
      <StyledCircle disabled={count >= max} onClick={handlePluseClick}>
        +
      </StyledCircle>
    </StyledContainer>
  );
};

export default Counter;
