import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { CONTENT_WIDTH } from '@/pages/Page';

export const StyledContainer = styled.div`
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  padding: 42px;

  .text {
    margin-bottom: 12px;
  }

  @media ${XS_QUERY} {
    padding: 12px 20px;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  overflow-x: auto;

  @media ${XS_QUERY} {
    width: 100%;
  }
`;
