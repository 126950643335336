import React from 'react';
import { XS_QUERY } from '@/breakpoints';
import styled from 'styled-components';
import { discordIcon, dominFooterImage, webIcon, xIcon } from '../../styles/imgMapping';

const StyledContainer = styled.div`
  width: 100%;
  border-top: 1px solid black;
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 50px 24px;
  max-width: 1440px;

  @media ${XS_QUERY} {
    padding: 20px 24px;
    flex-direction: column;
  }
`;

const StyledLogo = styled.a`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.bk70};

  &:visited, a:hover, a:active {
    color: inherit
  }

  .text {
    font-weight: 400;
    font-size: 10px;
    margin-top: 12px;
  }

  @media ${XS_QUERY} {
    align-items: center;
    margin-bottom: 32px;
  }

  img {
    width: 150px;
  }
`;

const StyledTextGroup = styled.div`
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  @media ${XS_QUERY} {
    margin-bottom: 32px;
  }

  div {
    &:not(:last-child) {
      margin-right: 48px;
    }
  }
`;

const StyledIconGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  a {
    display: block;
    height: 32px;

    &:hover {
      opacity: 0.8;
    }

    &:not(:last-child) {
      margin-right: 24px;
    }

    img {
      height: 32px;
    }
  }
`;

const list = [
  {
    key: 'web',
    default: webIcon,
    hidden: false,
    url: 'https://domin.foundation',
  },
  {
    key: 'x',
    default: xIcon,
    hidden: false,
    url: 'https://x.com/domin_network',
  },
  {
    key: 'discord',
    default: discordIcon,
    hidden: false,
    url: 'https://discord.com/invite/HcG4bZQzkn',
  },
];

const date = new Date();
const year = date.getFullYear();

const Footer = () => (
  <StyledContainer>
    <StyledContent>
      <StyledLogo href="https://domin.foundation" target="_blank" rel="noreferrer noopener">
        <img alt="redreamer logo" src={dominFooterImage} />
        <span className="text">{`© ${year} by Domin Network. All rights reserved.`}</span>
      </StyledLogo>
      <StyledIconGroup>
        {list.map(
          (l) =>
            !l.hidden && (
              <a key={l.key} href={l.url} target="_blank" rel="noreferrer noopener">
                <img alt={l.key} src={l.default} />
              </a>
            ),
        )}
      </StyledIconGroup>
    </StyledContent>
  </StyledContainer>
);

export default Footer;
