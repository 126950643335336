import React from 'react';
import { StyledContainer, StyledLabel, StyledLevelLabel, StyledProgress, StyledProgressBar } from './Styles';

type Props = {
  min: number;
  max: number;
  value: number;
  label: string | React.ReactNode;
  level?: number;
  className?: string;
  height?: number;
  backgroundColor?: string;
};

const ProgressBar = ({
  className,
  min,
  max,
  value,
  label,
  level,
  height = 50,
  backgroundColor = 'linear-gradient(rgba(141, 62, 255, 1), rgba(88, 0, 214, 1), rgba(181, 61, 255, 1))',
}: Props) => {
  const percentage = ((value / (max - min)) * 100).toFixed(2);

  return (
    <StyledContainer className={className}>
      <StyledProgressBar height={height}>
        <StyledProgress backgroundColor={backgroundColor} style={{ width: `${percentage}%` }} />
        <StyledLabel>{label}</StyledLabel>
        {!!level && (
          <StyledLevelLabel>
            <div className="label">{`LV.${level}`}</div>
          </StyledLevelLabel>
        )}
      </StyledProgressBar>
    </StyledContainer>
  );
};

export default ProgressBar;
