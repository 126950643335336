import React from 'react';
import { Airdrop } from '@pages/Airdrop';
import { Connect } from '@pages/Connect';
import { Contribute } from '@pages/Contribute';
import { Dashboard } from '@pages/Dashboard';
import { HomeNew } from '@pages/HomeNew';
import { SHOW_DODO_BIRD } from '@/config';

export default [
  {
    id: 'home',
    name: 'HomeNew',
    path: '/',
    hidden: false,
    component: (props: any) => <HomeNew {...props} description="HomeNew" />,
  },
  {
    id: 'airdrop',
    name: 'airdrop',
    path: '/airdrop',
    hidden: false,
    auth: true,
    component: (props: any) => <Airdrop {...props} description="Airdrop" />,
  },
  {
    id: 'contribute',
    name: 'Contribute',
    path: '/contribute',
    hidden: false,
    auth: true,
    component: (props: any) => <Contribute {...props} description="Contribute" />,
  },
  {
    id: 'dodobird',
    name: 'DoDoBird',
    path: '/dodobird',
    hidden: !SHOW_DODO_BIRD,
    auth: true,
    component: (props: any) => <Dashboard {...props} description="DoDoBird" />,
  },
  /*
  {
    id: 'Connect',
    name: 'connect',
    path: '/connect',
    component: (props: any) => <Connect {...props} description="Connect" />,
  },
  {
    id: 'error',
    name: 'Error',
    path: '/error',
    component: (props: any) => <div>haha</div>,
  },
  */
];
