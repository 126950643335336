import React, { useEffect } from 'react';
import { useDisconnect, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Logger } from '@api/logger';
import { getSwitchNetworks } from '@configs/evmWalletConfigs';
import customConfig from '@styles/customConfig';
import { WebsiteBgImage } from '@styles/imgMapping';
import { XS_QUERY } from '@/breakpoints';
import { ErrorModal } from '@/components/ErrorModal';
import { Footer } from '@/components/Footer';
import { Navbar } from '@/components/Navbar';
import useApplicationContext from '@/providers/applicationContext';
import { useAuthContext } from '@/providers/authProvider';
import { AppError, PageProvider, usePageContext } from '@/providers/pageProvider';
import { StyledDesktopLayoutContent } from '../globalStyle';

export const CONTENT_WIDTH = 960;

const StyledContainer = styled.div`
  position: relative;
  background: url(${WebsiteBgImage});
  background-color: ${({ theme }) => theme.colors.white};
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const StyledContent = styled.div<{ width: string }>`
  max-width: ${({ width }) => width || '1440px'};
  margin: auto;

  @media ${XS_QUERY} {
    width: 100%;
    padding: 12px;
  }
`;

const StyledAutoSpace = styled.div`
  flex: 1;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
  contentWidth?: string;
  auth?: boolean;
};

const Page = ({ children, contentWidth = '1440px', auth = false }: Props) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const { isMainnet, currentChainType, joyIdAddress, setJoyIdAddress } = useApplicationContext();
  const { authToken, resetAuth } = useAuthContext();
  const { error, updateError } = usePageContext();

  const { disconnect } = useDisconnect();

  const history = useHistory();

  const onRedirectToHome = () => {
    history.push('/');
  };

  if (auth && !authToken) {
    history.replace('/');
  }

  const onLogError = (error: Error, account?: string | null) => {
    Logger.error({
      error,
      message: `Switch network error with address: ${account}`,
    });
  };

  const handleLogout = async () => {
    resetAuth();
    setJoyIdAddress('');

    if (address && isConnected) {
      disconnect();
      localStorage.clear();
    }
  };

  return (
    <StyledContainer>
      <StyledAutoSpace>
        <Navbar
          walletAddress={address || ''}
          isMainnet={isMainnet}
          currentChainType={currentChainType}
          onResetAuth={handleLogout}
          onLogoClick={onRedirectToHome}
        />
        <StyledContent width={contentWidth}>{children}</StyledContent>
      </StyledAutoSpace>
      <Footer />
      <ErrorModal title="Error" opened={!!error} onClose={() => updateError(undefined)} error={error as AppError} />
    </StyledContainer>
  );
};

const PageWithProvider = (props: Props) => {
  const { children } = props;
  return (
    <PageProvider>
      <Page {...props}>{children}</Page>
    </PageProvider>
  );
};

export default PageWithProvider;
