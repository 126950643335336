import React from 'react';
import ReactMarkdown from 'react-markdown';

export const faq = [
  {
    id: 1,
    title: '1. What is the Domin Pre-registration Event?',
    content:
      'The Domin Pre-registration involves partnerships with various projects. Participants complete quests to enter partnership raffles for $Domin or contribute directly to earn $Domin.',
  },
  {
    id: 2,
    title: '2. How can I earn $Domin?',
    content: (
      <ReactMarkdown>
        {
          "### Entry Level: \n\n Everyone can participate, and everyone has a chance to win a DoDo Bird NFT to enter the Pro Level. \n\n 1. Airdrops: Win tokens through partnership raffles. \n\n 2. Contribute: Complete contribution quests. Note that the daily supply of tokens for contributions is limited. \n\n ### Pro Level: DoDo Bird NFT \n\n Once you gain the DoDo Bird NFT, you join the Top 5000 list. We've allocated 60% of the airdrop rewards for DoDo Bird holders, meaning you’ll secure a 50-100X bonus compared to staying in the Entry Level."
        }
      </ReactMarkdown>
    ),
  },
  {
    id: 3,
    title: '3. How do I get a DoDo Bird NFT?',
    content: 'The first 4800 users who accumulate 250 $Domin can free mint a DoDo Bird NFT.',
  },
  {
    id: 4,
    title: '4. What’s the distribution of DoDo Bird NFTs',
    content: (
      <ReactMarkdown>
        {
          'There are 5000 DoDo Bird NFTs in total: \n\n 1. 4800 for the first 4800 users who accumulate 250 $Domin. \n\n 2. 150 reserved for partners and marketing strategies. \n\n 3. 50 for community members participating in the Boost Bird event.'
        }
      </ReactMarkdown>
    ),
  },
  {
    id: 5,
    title: '5. Why should I aim to get a DoDo Bird?',
    content:
      'Owning a DoDo Bird means you’ve entered a bonus prize pool containing 6M $Domin for 5000 people. We will distribute the bonus rewards based on your ranking. Being the top-ranked guarantees you a 100 times of  $Domin prize than the reward from you complete a daily contribution. Even if you’re ranked lower, you can still get at least 50 times of $Domin as a bonus.',
  },
  {
    id: 6,
    title: '6. What happens if the daily supply of the contribution token pool reaches the cap?',
    content:
      'If the daily supply for contributions is exhausted, you will have to wait until the next day to contribute again. This ensures a fair distribution of tokens.',
  },
  {
    id: 7,
    title: '7. When can I claim my $Domin?',
    content:
      '$Domin given to the community are centrally recorded and will be claimable at the Token Generation Event (TGE).',
  },
  {
    id: 8,
    title: '8. What are the DoDo Bird Essentials?',
    content: (
      <ReactMarkdown>
        {
          'DoDo Bird Essentials encompass everything that can nourish your DoDo Bird, including worms, fat worms, bugs, and more. \n\n Feeding them to the Dodo Bird will allow you to accumulate more miles, unlocking additional $Domin. The further it flies, the more $Domin you will receive in the future.'
        }
      </ReactMarkdown>
    ),
  },
  {
    id: 9,
    title: '9. How do I mint DoDo Essentials after getting DoDo Bird?',
    content:
      'Complete the tasks in the contribution section and press “claim reward”. Once the data is updated, you can press the “Mint” button to mint.',
  },
  {
    id: 10,
    title: '10. When will my $Domin allocation be renewed?',
    content: (
      <ReactMarkdown>
        {
          '**Airdrop campaign:** \n\n Once the raffles end, we’ll sync with the Galxe results every hour and renew the allocation. \n\n **AMA raffle:** \n\n Allocations for AMA raffles will be updated 1 day after the winners are drawn. \n\n **Contribute:** \n\n Your allocation will update once you complete the task and press the “claim reward” button.'
        }
      </ReactMarkdown>
    ),
  },
];
