import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Logger } from '@api/logger';
import { useGetContribute } from '@/api/getContribute';
import { useGetDailySignIn } from '@/api/getDailySignIn';
import { useGetRedeemCampaigns } from '@/api/getRedeemCampaigns';
import { useGetRedemptions } from '@/api/getRedemptions';
import { useGetReferralCode } from '@/api/getReferralCode';
import { usePostDailySignIn } from '@/api/postDailySignIn';
import { usePostRedeem } from '@/api/postRedeem';
import { ProgressBar } from '@/components/ProgressBar';
import { Rank } from '@/components/Rank';
import { SuccessModal } from '@/components/SuccessModal';
import { IS_FINISHED, SHOW_DODO_BIRD } from '@/config';
import Page from '@/pages/Page';
import useApplicationContext from '@/providers/applicationContext';
import { AppError, usePageContext } from '@/providers/pageProvider';
import { Event } from '../Airdrop/Event';
import { ContributeEvent, ContributeProps, Status } from './ContributeEvent';
import { MintModal } from './ContributeEvent/MintModal';
import {
  StyledContainer,
  StyledContent,
  StyledLabelContainer,
  StyledNotice,
  StyledProgress,
  StyledSection,
} from './Styles';

const topEvent = {
  title: 'Compete and become the first 4800 winners to win DoDo Bird NFT, unlocking additional 6M $DOMIN for winning',
  color: 'YellowGradient',
  link: '/dodobird',
};

type Props = {
  auth?: boolean;
};

const yellowGradient = 'linear-gradient(rgb(255, 199, 0.7), rgb(230, 152, 0.7), rgb(255, 214, 0.7))';
const greenGradient = 'linear-gradient(rgb(0, 255, 41), rgb(0, 184, 30), rgb(0,255,41))';
const grayGradient = 'linear-gradient(rgb(210, 210, 210), rgb(158, 158, 158), rgb(210, 210, 210))';
const purpleGradient = 'linear-gradient(rgb(141, 62, 255), rgb(88, 0, 214), rgb(181, 61, 255))';

const getColor = (isActive = false) => (isActive ? yellowGradient : greenGradient);

const Contribute = ({ auth = false }: Props) => {
  const [openMintModal, setOpenMintModal] = useState(false);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [redemptions, setRedemptions] = useState<any[]>([]);
  const [contribute, setContribute] = useState<any>({});
  const [referral, setReferral] = useState<any>({});
  const [daily, setDaily] = useState<any>({});
  const [dailyCompletion, setDailyCompletion] = useState(false);
  const { getContribute } = useGetContribute();
  const { getDailySignIn } = useGetDailySignIn();
  const { postDailySignIn } = usePostDailySignIn();
  const { getReferralCode } = useGetReferralCode();
  const { getRedeemCampaigns } = useGetRedeemCampaigns();
  const { getRedemptions } = useGetRedemptions();
  const { postRedeem } = usePostRedeem();
  const { popup, setPopup, updateRank, handlePopupClose } = useApplicationContext();

  const { updateError } = usePageContext();
  const mockTotalSupply = {
    maxValue: 3000000,
    value: 100000,
  };
  const mockDailySupply = {
    maxValue: 50000,
    value: 100,
  };

  const handleGetCampaigns = () => {
    getRedeemCampaigns()
      .then((data) => {
        setCampaigns(data || []);
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
        setCampaigns([]);
      });
  };

  const handleDailySignIn = () => {
    postDailySignIn()
      .then(() => {
        setDailyCompletion(true);
        handleGetDailySignIn();
        updateRank();
        setPopup({
          opened: true,
          title: '+1.5 $DOMIN & Signed in',
          description: 'Complete the other quests right now!',
        });
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        handlePopupClose();
        updateError(error);
      });
  };

  const handleGetRedemption = () =>
    getRedemptions()
      .then((data) => {
        setRedemptions(data);
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
        setRedemptions([]);
      });

  const handleCopyCode = () => {
    if (referral?.referralCode) {
      navigator.clipboard.writeText(referral?.referralCode || '');
    }
  };

  const getRedeemHandler =
    ({ id = 0, title = '', description = '' }) =>
    () => {
      postRedeem({ id })
        .then(() => {
          handleGetRedemption();
          updateRank();
          setPopup({
            opened: true,
            title,
            description,
          });
        })
        .catch((e) => {
          const error = e as AppError;
          Logger.error({ error });
          updateError(error);
        });
    };

  const getHandleClaimReward =
    ({ title = '', description = '' }) =>
    () => {
      handleGetAllContributes().then(() => {
        /*
        setPopup({
          opened: true,
          title,
          description,
        });
        */
      });
    };

  const handleGetAllContributes = () =>
    getContribute()
      .then((data) => {
        setContribute(data);
        updateRank();
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
        setContribute({});
      });

  const handleGetDailySignIn = () =>
    getDailySignIn()
      .then((data) => {
        setDaily(data);
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
        setDaily({});
      });

  const handleMintModal = () => {
    setOpenMintModal(true);
  };

  useEffect(() => {
    handleGetAllContributes();
    handleGetDailySignIn();
    handleGetCampaigns();
    handleGetRedemption();
    getReferralCode()
      .then((data) => {
        setReferral(data);
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
        setDaily({});
      });
  }, []);

  const getDominLabel = (totalSupply: any = {}) => (
    <StyledLabelContainer>
      <div className="value">{totalSupply?.value}</div>
      {' / '}
      <div className="max-value">{new Intl.NumberFormat('en-US').format(totalSupply?.total || 0)}</div>
      $DOMIN
    </StyledLabelContainer>
  );

  return (
    <Page contentWidth="100%" auth={auth}>
      <StyledContainer>
        <Rank />
        <StyledContent>
          <div className="content-title">ContriBute</div>
          <div className="Events">
            <Event title={topEvent.title} color={topEvent.color} link={topEvent.link} />
          </div>
          <StyledProgress>
            <div className="title">Total Supply For Contribute</div>
            <ProgressBar
              min={0}
              max={contribute?.totalSupply?.total}
              value={contribute?.totalSupply?.value}
              height={50}
              label={getDominLabel(contribute?.totalSupply)}
            />
          </StyledProgress>
          <StyledSection>{IS_FINISHED ? 'One-time quests (Finished)' : 'One-time quests'}</StyledSection>
          <div className="contributes">
            {(contribute?.oneTimeQuest || []).map((c: any = {}) => (
              <ContributeEvent
                key={c.name}
                status={c.isActive ? Status.ACTIVE : Status.END}
                description={c.description}
                title={c.name}
                actionName={c.action}
                actionName2="Claim Reward"
                action={handleGetAllContributes}
                link={c.url}
                color={getColor(c.isActive)}
                dominCount={c.domin}
                image={c.image}
              />
            ))}
            <ContributeEvent
              title={`Your referral code: ${referral?.referralCode}`}
              description={`${referral?.referrals || 0}/5 referred (max 5 referrals in total)`}
              actionName="Copy"
              dominCount={2}
              action={handleCopyCode}
            />
          </div>
          <StyledSection>Complete quests below in sequence</StyledSection>
          <StyledProgress>
            <div className="title">{IS_FINISHED ? 'Daily Supply Finished.' : 'Total Daily Supply For Contribute, refresh every UTC 00:00'}</div>
            {!IS_FINISHED && (
              <ProgressBar
                min={0}
                max={contribute?.dailySupply?.total}
                value={contribute?.dailySupply?.value}
                height={50}
                label={getDominLabel(contribute?.dailySupply)}
              />
            )}
          </StyledProgress>
          <StyledNotice>
            {IS_FINISHED
              ? 'Please stay tuned with our community announcement to know about the other rewards. Join & follow our X and Discord right now!'
              : '*DoDo Bird holders will get DoDo Essential NFTs after completing these quests. DoDo Essential NFTs will be airdropped every Friday at 9am UTC, based on participation for the whole week up to that time.'}
          </StyledNotice>
          <div className="contributes">
            {daily?.action && (
              <ContributeEvent
                status={daily?.isActive ? Status.ACTIVE : Status.END}
                description={daily?.description}
                title="Daily Sign In"
                actionName={daily?.action}
                dominCount={daily?.domin}
                color={getColor(daily?.isActive)}
                action={handleDailySignIn}
                image={daily?.image}
              />
            )}
            {(contribute?.dailyQuest || []).map((c: any = {}) => (
              <ContributeEvent
                key={c.name}
                status={c.isActive ? Status.ACTIVE : Status.END}
                description={c.description}
                title={c.name}
                actionName="Go to Galxe"
                actionName2="Claim Reward"
                action={getHandleClaimReward({
                  title: `+${c?.domin || 0} $DOMIN & Reward Claimed`,
                  description: 'Complete the other quests right now!',
                })}
                link={c.url}
                color={getColor(c.isActive)}
                dominCount={c.domin}
                image={c.image}
              />
            ))}
            {(campaigns || []).map((c: any = {}) => (
              <ContributeEvent
                key={c.name}
                status={c.isActive ? Status.ACTIVE : Status.END}
                description={c.description}
                title={c.name}
                actionName="Redeem"
                action={getRedeemHandler({
                  id: c?.id,
                  title: `+${c?.domin || 0} $DOMIN & Coupon Redeemed`,
                  description: 'Check the coupon below and enjoy the discount!',
                })}
                color={getColor(c.isActive)}
                dominCount={c.domin}
                image={c.image}
              />
            ))}
          </div>
          <StyledSection>Final Task</StyledSection>
          <div className="contributes">
            <ContributeEvent
              status={Status.END}
              description="Gas fee required. Rewards you get will be calculate depends on the amount of activity you participated in contribute. It will be claimable around the end of contribution period."
              bigTitle="Record your data on chain"
              actionName="Claim"
              color={purpleGradient}
              label="Reveal in the final stage"
            />
          </div>
          <StyledSection>Redeemed items</StyledSection>
          {redemptions.length === 0 && <div>No Redemptions.</div>}
          <div className="contributes">
            {redemptions.map((redemption) => (
              <ContributeEvent
                key={redemption?.name}
                title={redemption?.name}
                description={redemption?.description}
                redemption={<ReactMarkdown>{redemption?.redemption}</ReactMarkdown>}
                link={redemption?.url}
                actionName="Check"
                showCount={false}
              />
            ))}
          </div>
        </StyledContent>
      </StyledContainer>
      <SuccessModal
        title={popup?.title}
        opened={popup?.opened}
        onClose={handlePopupClose}
        message={popup?.description}
      />
      <MintModal
        title="Mint"
        message="You are going to mint the DoDo Essentials!  Please make sure which items you want to mint."
        opened={openMintModal}
        onClose={() => {}}
      />
    </Page>
  );
};

const mockContributes: ContributeProps[] = [
  {
    title: 'Win Big with DoDo Bird NFT',
    status: Status.ACTIVE,
    description: 'Contribute to win DoDo Bird NFT',
    actionName: 'Participate',
    link: '/dashboard',
    dominCount: 1000,
  },
  {
    title: 'Claim Your $DOMIN Reward',
    status: Status.END,
    description: 'Contribute to win additional 6M $DOMIN',
    actionName: 'Claim',
    link: '/claim',
    color: 'RedGradient',
    dominCount: 2000,
  },
  {
    title: 'Unlock Exclusive Rewards',
    status: Status.ACTIVE,
    description: 'Contribute to unlock exclusive rewards',
    actionName: 'Unlock',
    link: '/unlock',
    color: 'GreenGradient',
    dominCount: 3000,
  },
];

const mockRedemptions = [
  {
    name: 'Redemption 1',
    description: 'Description for Redemption 1',
    redemption: 'Redemption 1',
    url: 'https://example.com/redemption1',
  },
  {
    name: 'Redemption 2',
    description: 'Description for Redemption 2',
    redemption: 'Redemption 2',
    url: 'https://example.com/redemption2',
  },
  {
    name: 'Redemption 3',
    description: 'Description for Redemption 3',
    redemption: 'Redemption 3',
    url: 'https://example.com/redemption3',
  },
];

export default Contribute;
