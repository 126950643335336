import styled, { css } from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { CONTENT_WIDTH } from '@/pages/Page';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${XS_QUERY} {
    flex-direction: column;
  }

  .image {
    width: 50%;
    border-radius: 24px;
    object-fit: contain;

    @media ${XS_QUERY} {
      width: 100%;
      flex-direction: column;
    }
  }
`;

export const StyledButtonGroup = styled.div`
  padding: 24px;
  width: 100%;
  margin-left: 24px;

  @media ${XS_QUERY} {
    margin-left: 0;
  }

  .btn-container {
    margin-bottom: 24px;

    .icon {
      filter: invert(100%) sepia(63%) saturate(2%) hue-rotate(227deg) brightness(110%) contrast(101%);
    }

    .label {
        font-weight: 900;
        font-size: 24px;
        margin-bottom: 24px;
      @media ${XS_QUERY} {
        font-size: 20px;
      }
    }

    .bar {
        border-radius: 12px;
        margin-bottom: 12px;
        min-height: 54px;
        width: 100%;
        color: white;
        padding 12px;
        background: linear-gradient(rgba(255, 199, 0, 1), rgba(230, 152, 0, 1), rgba(255, 214, 0, 1));
    }

    .input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .input {
        margin-bottom: 12px;
        border-radius: 12px;
        height: 50px;
        width: 100%;
        padding: 6px 24px;
        border: 1px solid ${({ theme }) => theme.colors.dodoPurple};
        margin-bottom: 24px;
    }
  }

`;

export const StyledFaqContainer = styled.div`
  width: ${CONTENT_WIDTH}px;
  margin: auto;

  @media ${XS_QUERY} {
    width: 100%;
  }

  .paragraph {
    font-weight: 900;
    font-size: 48px;
    margin-bottom: 24px;
  }
`;
