import React, { useState } from 'react';
import testNFTImage01 from '@/assets/testNFTImage01.png';
import testNFTImage02 from '@/assets/testNFTImage02.png';
import { Rank } from '@/components/Rank';
import Page from '@/pages/Page';
import { INFT } from '@/types';
import { StyledContainer, StyledContent } from './Styles';
import { NFTDetail } from './components/NFTDetail';

type Props = {
  auth?: boolean;
};

const Dashboard = ({ auth = false }: any) => {
  console.log('');
  return (
    <Page contentWidth="100%" auth={auth}>
      <StyledContainer>
        <Rank />
        <StyledContent>
          <div className="content-title">DoDo Bird</div>
          <NFTDetail />
        </StyledContent>
      </StyledContainer>
    </Page>
  );
};

export default Dashboard;
