import React, { useEffect, useState } from 'react';
import { Logger } from '@api/logger';
import { useGetAirdrop } from '@/api/getAirdrop';
import { Rank } from '@/components/Rank';
import Page from '@/pages/Page';
import { AppError, usePageContext } from '@/providers/pageProvider';
import { Event, EventProps, Status } from './Event';
import { StyledContainer, StyledContent } from './Styles';

const topEvent = {
  title: 'Additional 3M $DOMIN For Winning in Contribute',
  color: 'YellowGradient',
  link: '',
};

type Props = {
  auth?: boolean;
};

const Airdrop = ({ auth = false }: Props) => {
  const [airdropEvents, setAirdropEvents] = useState<any[]>([]);
  const { getAirdrop } = useGetAirdrop();
  const { updateError } = usePageContext();
  useEffect(() => {
    getAirdrop()
      .then((data) => {
        setAirdropEvents(data);
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
        setAirdropEvents([]);
      });
  }, []);
  return (
    <Page contentWidth="100%" auth={auth}>
      <StyledContainer>
        <Rank />
        <StyledContent>
          <div className="content-title">Airdrop</div>
          <div className="Events">
            <Event title={topEvent.title} color={topEvent.color} link={topEvent.link} />
            {airdropEvents.map((e: any) => (
              <Event
                key={e.name}
                title={e.name}
                status={e.isActive ? Status.ACTIVE : Status.END}
                startDate={e.startAt}
                endDate={e.endAt}
                link={e.url}
              />
            ))}
          </div>
        </StyledContent>
      </StyledContainer>
    </Page>
  );
};

const mockData: EventProps[] = [
  {
    title: 'Event 1',
    status: Status.ACTIVE,
    startDate: new Date('2022-01-01').toISOString(),
    endDate: new Date('2022-01-01').toISOString(),
    link: 'https://example.com/event1',
  },
  {
    title: 'Event 2',
    status: Status.END,
    startDate: new Date('2022-01-15').toISOString(),
    endDate: new Date('2022-01-15').toISOString(),
    link: 'https://example.com/event2',
  },
  {
    title: 'Event 3',
    status: Status.ACTIVE,
    startDate: new Date('2022-02-01').toISOString(),
    endDate: new Date('2022-02-01').toISOString(),
    link: 'https://example.com/event3',
  },
  {
    title: 'Event 4',
    status: Status.END,
    startDate: new Date('2022-03-01').toISOString(),
    endDate: new Date('2022-03-01').toISOString(),
    link: 'https://example.com/event4',
  },
  {
    title: 'Event 5',
    status: Status.ACTIVE,
    startDate: new Date('2022-04-01').toISOString(),
    endDate: new Date('2022-04-01').toISOString(),
    link: 'https://example.com/event5',
  },
];

export default Airdrop;
