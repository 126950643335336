import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { useLogin } from '@api/auth/login';
import { Logger } from '@api/logger';

type Props = {
  children: React.ReactNode;
};

interface ILoginParams {
  network: string;
  address: string;
  signature: string;
}

interface ContextType {
  authToken: string;
  authRefreshToken: string;
  login: (params: ILoginParams) => Promise<void>;
  resetAuth: () => void;
  googleToken?: string;
  setGoogleToken: React.Dispatch<React.SetStateAction<string>>;
}

const defaultAuth = {
  authToken: '',
  authRefreshToken: '',
};

const AuthContext = createContext<ContextType>({
  ...defaultAuth,
  login: () => Promise.resolve(),
  resetAuth: () => {},
  googleToken: '',
  setGoogleToken: () => {},
});

const useAuthProvider = () => {
  const [auth, setAuth] = useState(defaultAuth);
  const [googleToken, setGoogleToken] = useState('');
  const { authLogin } = useLogin();

  const login = async ({ network, address, signature }: ILoginParams) => {
    try {
      const data = await authLogin({ network, address, signature });
      Cookies.set('token', data.accessToken);
      Cookies.set('refreshToken', data.refreshToken);
      setAuth({
        authToken: data.accessToken,
        authRefreshToken: data.refreshToken,
      });
    } catch (e) {
      Logger.error({ error: e as Error, message: 'login error' });
    }
  };

  const resetAuth = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    setGoogleToken('');

    setAuth(defaultAuth);
  };

  return {
    ...auth,
    login,
    resetAuth,
    googleToken,
    setGoogleToken,
  };
};

export const AuthProvider = ({ children }: Props) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
