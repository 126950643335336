import React from 'react';

export enum Status {
  ACTIVE = 'active',
  END = 'ended',
}

export type Props = {
  status?: Status;
  title?: string;
  bigTitle?: string;
  subTitle?: string | React.ReactNode;
  description?: string | React.ReactNode;
  redemption?: string | React.ReactNode;
  actionName?: string;
  actionName2?: string;
  link?: string;
  color?: string;
  dominCount?: number;
  hasBug?: number;
  label?: string;
  image?: string;
  onChain?: string;
  offChain?: string;
  action?: () => void;
  showCount?: boolean;
};
