import React from 'react';
import theme from '@/styles/theme';
import { Spinner } from '../Spinner';
import { StyledMintButton } from './Styles';

const Button = ({ children, loading, disabled, type, buttonType, ...props }: any) => (
  <StyledMintButton disabled={disabled || loading} type={type} buttonType={buttonType} {...props}>
    {loading ? <Spinner style={{ height: '16px' }} /> : children}
  </StyledMintButton>
);

export default Button;
