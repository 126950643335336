import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { Tabs } from '@/components/Tabs';
import { StyledTabList, StyledTabListContainer } from '@/components/Tabs/Styles';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledWrappedTabs = styled(Tabs)`
  @media ${XS_QUERY} {
    font-size: 12px;
  }
  .react-tabs__tab-panel {
    width: 100%;
  }

  @media ${XS_QUERY} {
    margin-bottom: 24px;
  }

  &&& ${StyledTabListContainer} {
    margin-bottom: 24px;
  }

  &&& ${StyledTabList} {
    margin-bottom: 36px;
    justify-content: flex-start;
    width: fit-content;
    margin: auto;
    margin-left: 0px;

    @media ${XS_QUERY} {
      padding: 8px;
      font-size: 12px;
    }
  }
`;
