import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Logger } from '@api/logger';
import { useGetMyCollection } from '@/api/getMyCollection';
import testNFTImage01 from '@/assets/testNFTImage01.png';
import testNFTImage02 from '@/assets/testNFTImage02.png';
import { AppError, usePageContext } from '@/providers/pageProvider';
import { INFT, IWorm } from '@/types';
import { CollectionPanel } from '../CollectionPanel';
import { NFTSelector } from '../NFTSelector';
import { NFTSummary } from '../NFTSummary';
import { StyledContainer, StyledLine } from './Styles';

type Props = {
  nft: INFT | undefined;
};

const testNFTs: INFT[] = Array.from({ length: 10 }, (_, index) => {
  const tokenId = index + 1;
  const image = tokenId % 2 === 0 ? testNFTImage02 : testNFTImage01;

  return {
    name: `NFT ${tokenId}`,
    description: `Description for NFT ${tokenId}`,
    image,
    miles: 100,
    rank: 42,
    tokenId: index + 1,
    attributes: [
      { traitType: 'miles', value: 10, maxValue: 1000000 },
      { traitType: '$tdomin', value: 20, maxValue: 30 },
    ],
  };
});

const NFTPanel = () => {
  const [activeNFT, setActiveNFT] = useState<INFT>();
  const [nfts, setNFTs] = useState<INFT[]>([]);
  const [worms, setWorms] = useState<IWorm[]>([]);
  const { updateError } = usePageContext();
  const { getMyCollection } = useGetMyCollection();
  const handleSelect = (nft: INFT) => {
    setActiveNFT(nft);
  };

  const handleGetMyCollection = () =>
    getMyCollection()
      .then((data) => {
        const { birds = [], worms = [] } = data || {};
        setNFTs(birds);
        setWorms(worms);
      })
      .catch((e) => {
        const error = e as AppError;
        Logger.error({ error });
        updateError(error);
        setNFTs([]);
      });

  const handleSuccess = () => {
    handleGetMyCollection();
  };

  useEffect(() => {
    handleGetMyCollection();
  }, []);

  return (
    <StyledContainer>
      <NFTSelector activeNFT={activeNFT} handleSelect={handleSelect} nfts={nfts} />
      {activeNFT && (
        <>
          <StyledLine />
          <NFTSummary nft={activeNFT} />
          <StyledLine />
          <div className="links">
            <a
              href="https://opensea.io/collection/unidentified-contract-13144697-d606-477b-a6cb-6d25"
              target="_blank"
              rel="noreferrer noopener"
            >
              OpenSea
            </a>
            <Link to="/contribute">Collect More Food</Link>
          </div>
          <CollectionPanel onSuccess={handleSuccess} nft={activeNFT} worms={worms} />
        </>
      )}
    </StyledContainer>
  );
};

export default NFTPanel;
