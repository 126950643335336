import React from 'react';
import { ButtonType } from '@redreamerlab/uikit';
import { isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button } from '@components/Button';
import { Props, Status } from './Props';
import { StyledContainer, StyledContent, StyledCount } from './Styles';

const ContributeEvent = ({
  status,
  link,
  dominCount,
  title,
  bigTitle,
  subTitle,
  description,
  redemption,
  color,
  actionName,
  actionName2,
  action = () => {},
  label,
  image,
  onChain,
  offChain,
  showCount = true,
}: Props) => {
  const history = useHistory();
  const disabled = status === Status.END;
  const handleClick = () => {
    if (disabled) {
      return;
    }
    if (link) {
      if ((link || '').startsWith('http')) {
        window.open(link, '_blank', 'noopener');
      } else if ((link || '').startsWith('/')) {
        history.push(link);
      }
      return;
    }
    if (action) {
      action();
    }
  };

  const handleClick2 = () => {
    if (disabled) {
      return;
    }
    if (actionName2 && action) {
      action();
    }
  };

  return (
    <StyledContainer>
      {showCount && (
        <StyledCount color={color}>
          {!isNil(dominCount) && <div className="dominCount">{`+${dominCount || 0}`}</div>}
          {!isNil(label) && <div className="label">{label}</div>}
          {image && (
            <div className="bug">
              +
              <img alt="bug" className="bug-image" src={image} />
            </div>
          )}
        </StyledCount>
      )}
      <StyledContent>
        <div className="text">
          {!isNil(title) && <div className="title">{title}</div>}
          {!isNil(bigTitle) && <div className="big-title">{bigTitle}</div>}
          {!isNil(subTitle) && <div className="sub-title">{subTitle}</div>}
          {!isNil(offChain) && <div className="chain">{offChain}</div>}
          {!isNil(onChain) && <div className="chain">{onChain}</div>}
          <div className="description">{description}</div>
          {redemption && <div className="description">{redemption}</div>}
        </div>
        <div className="button-container">
          <Button size="small" disabled={disabled} onClick={handleClick} type="button" buttonType={ButtonType.GENERAL}>
            {actionName || 'Click'}
          </Button>
          {actionName2 && (
            <>
              <br />
              <br />
              <Button
                size="small"
                disabled={disabled}
                onClick={handleClick2}
                type="button"
                buttonType={ButtonType.GENERAL}
              >
                {actionName2 || 'Click'}
              </Button>
            </>
          )}
        </div>
      </StyledContent>
    </StyledContainer>
  );
};

export default ContributeEvent;
