import React, { useEffect } from 'react';
import { Row } from '@/styles/common';
import { StyledMessage, StyledModal, StyledTitle } from './Styles';

type Props = {
  title: string;
  opened: boolean;
  onClose: () => void;
  message: string;
  showIcon?: boolean;
};

const MintModal = ({ title, opened, onClose, message, showIcon = true }: Props) => {
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    if (opened) {
      // TODO mint process
    }
  }, [opened]);
  return (
    <StyledModal hasBackDrop opened={opened} onClose={handleClose} width="450" disabledCloseButton>
      <Row direction="column" align="center">
        <div>
          <StyledTitle>{title}</StyledTitle>
          <StyledMessage>{message}</StyledMessage>
          <br />
        </div>
      </Row>
      mint modal
    </StyledModal>
  );
};

export default MintModal;
