import React from 'react';
import { StyledContainer } from './Styles';

type Props = {
  children: React.ReactNode;
  transparent?: boolean;
};

const SectionCard = ({ children, transparent = false }: Props) => (
  <StyledContainer transparent={transparent}>{children}</StyledContainer>
);

export default SectionCard;
