import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  border-radius: 24px;
  padding: 48px;
  border: 1px solid ${({ theme }) => theme.colors.whited2};
  width: 100%;

  .content {
    font-size: 20px;
    font-weight: 900;

    @media ${XS_QUERY} {
      font-size: 14px;
    }
  }

  @media ${XS_QUERY} {
    width: 100%;
    padding: 24px;
  }
`;
