import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 900;
  }

  .link {
    &:visited {
      color: #0080f6;
    }
  }
`;

export const StyledBar = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  border: 1px solid black;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 2px 2px;

  @media ${XS_QUERY} {
    height: 40px;
  }
`;

export const StyledCheckImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${XS_QUERY} {
    max-width: 50%;
  }
`;

export const Child1 = styled.div<{ index: number }>`
  position: relative;
  flex: 1;
  border-right: 1px solid black;
  cursor: pointer;
  background: ${({ index }) =>
    index === 1
      ? 'linear-gradient(rgba(141, 62, 255, 1), rgba(88, 0, 214, 1), rgba(181, 61, 255, 1))'
      : 'linear-gradient(0deg, rgba(210,210,210,1) 0%, rgba(158,158,158,1) 50%, rgba(210,210,210,1) 100%)'};
  border-radius: 40px 0 0 40px;
`;

export const Child2 = styled.div<{ index: number }>`
  position: relative;
  flex: 3;
  border-right: 1px solid black;
  cursor: pointer;
  background: ${({ index }) =>
    index === 2
      ? 'linear-gradient(rgba(141, 62, 255, 1), rgba(88, 0, 214, 1), rgba(181, 61, 255, 1))'
      : 'linear-gradient(0deg, rgba(210,210,210,1) 0%, rgba(158,158,158,1) 50%, rgba(210,210,210,1) 100%)'};
`;

export const Child3 = styled.div<{ index: number }>`
  position: relative;
  flex: 6;
  cursor: pointer;
  background: ${({ index }) =>
    index === 3
      ? 'linear-gradient(rgba(141, 62, 255, 1), rgba(88, 0, 214, 1), rgba(181, 61, 255, 1))'
      : 'linear-gradient(0deg, rgba(210,210,210,1) 0%, rgba(158,158,158,1) 50%, rgba(210,210,210,1) 100%)'};
  border-radius: 0 40px 40px 0;
`;

export const StyledTabs = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 150px;

  .first {
    display: flex;
    align-items: center;
  }

  .second {
    display: flex;
  }

  @media ${XS_QUERY} {
    margin-bottom: 100px;
  }
`;

export const StyledTab = styled.div<{ active?: boolean }>`
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 40px;
  font-weight: 900;
  justify-content: space-between;
  align-items: center;
  color: ${({ active = false, theme }) => (active ? theme.colors.dodoPurple : theme.colors.whited2)};
  cursor: pointer;
  z-index: 2;

  .image {
    height: 100%;
    @media ${XS_QUERY} {
      width: 30px;
    }
  }

  .dialog {
  }

  &:hover {
    color: ${({ theme }) => theme.colors.dodoPurpleHover};
  }

  @media ${XS_QUERY} {
    font-size: 16px;
    padding: 6px 12px;
  }
`;

export const StyledDialog = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 50%;
  right: 0;
  width: 50%;
  padding: 24px;
  line-height: 18px;
  border-radius: 32px;
  font-size: 14px;
  background: ${({ active = false, theme }) => (active ? theme.colors.dodoPurpleLight : theme.colors.whitef2)};
  color: ${({ active = false, theme }) => (active ? theme.colors.black : theme.colors.whited2)};
  z-index: 1;

  @media ${XS_QUERY} {
    font-size: 8px;
    line-height: 14px;
    padding 12px;
    border-radius: 16px;
  }

  .purple {
    display: inline;
    font-weight: 900;
    color: ${({ active = false, theme }) => (active ? theme.colors.dodoPurple : theme.colors.whited2)};
  }

  .big {
    font-size: 20px;
  }

  .bold {
    font-weight: 900;
  }
`;

export const StyledVesting = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  border: 2px solid ${({ theme }) => theme.colors.dodoPurple};
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.dodoPurpleLight};

  .group {
    display: flex;
    justify-content: space-between;

    @media ${XS_QUERY} {
      flex-direction: column;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 24px;

    @media ${XS_QUERY} {
      margin-bottom: 12px;
    }
  }

  .content {
    font-size: 16px;

    .list {
      li {
        margin-bottom: 12px;
      }
    }
  }

  .purple {
    color: ${({ theme }) => theme.colors.dodoPurple};
  }

  .inline {
    display: inline;
  }

  .bold {
    color: ${({ theme }) => theme.colors.dodoPurple};
    font-size: 40px;
    font-weight: 900;

    @media ${XS_QUERY} {
    }
  }
`;

export const StyledColumn = styled.div<{ flex: number }>`
  margin-bottom: 24px;
  width: 100%;
  flex: ${({ flex = 1 }) => flex};

  @media ${XS_QUERY} {
    flex: 1;
  }
`;
