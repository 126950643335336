/* eslint-disable react/forbid-prop-types */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { signMessage as joyIdSignMessage } from '@joyid/evm';
import { useWeb3React } from '@web3-react/core';
import { useDisconnect, useSwitchNetwork, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import get from 'lodash/get';
import { useHistory } from 'react-router';
import Web3 from 'web3';
import { useBindGoogle } from '@api/auth/bindGoogle';
import { useGetBindMessage } from '@api/auth/getBindMessage';
import { useGetNonce } from '@api/auth/getNonce';
import { Logger } from '@api/logger';
import { SelectWallet } from '@configs/evmConnecters';
import { chainMapping } from '@configs/evmWalletConfigs';
import { useInactiveListener } from '@/hooks/useInactiveListener';
import { signMessage, signTypedData } from '@/rpc';
import { useAuthContext } from './authProvider';
import { AppError } from './pageProvider';

type Props = {
  children: React.ReactNode;
};

interface ContextType {
  selectWallet: SelectWallet | undefined;
  setSelectWallet: React.Dispatch<React.SetStateAction<SelectWallet | undefined>>;
  web3?: Web3;
  currentChainType: string;
  isMainnet: boolean;
  contracts?: object;
  addressConfigs?: object;
  tokenNames?: any[];
  triggerSignWallet: boolean;
  setTriggerSignWallet: React.Dispatch<React.SetStateAction<boolean>>;
  appError: AppError | undefined;
  setAppError: React.Dispatch<React.SetStateAction<AppError | undefined>>;
  joyIdAddress: string;
  setJoyIdAddress: React.Dispatch<React.SetStateAction<string>>;
  deactivateWallet: () => Promise<void>;
  referralCode: string;
  setReferralCode: React.Dispatch<React.SetStateAction<string>>;
  rankPurge: number;
  updateRank: () => void;
  popup: any;
  setPopup: React.Dispatch<React.SetStateAction<any>>;
  handlePopupClose: () => void;
}

const ApplicationContext = createContext<ContextType>({
  selectWallet: undefined,
  setSelectWallet: () => {},
  web3: undefined,
  contracts: {},
  addressConfigs: {},
  tokenNames: [],
  currentChainType: '',
  isMainnet: false,
  triggerSignWallet: false,
  setTriggerSignWallet: () => {},
  appError: undefined,
  setAppError: () => {},
  deactivateWallet: () => Promise.resolve(),
  joyIdAddress: '',
  setJoyIdAddress: () => {},
  referralCode: '',
  setReferralCode: () => {},
  rankPurge: 0,
  updateRank: () => {},
  popup: {},
  setPopup: () => {},
  handlePopupClose: () => {},
});

const Provider = ({ children }: Props) => {
  const [popup, setPopup] = useState<any>({});
  const [referralCode, setReferralCode] = useState('');
  const [rankPurge, setRankPurge] = useState(0);
  const { address: account, isConnected, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const userAddress = (account || '').toLowerCase();
  const { login, resetAuth, googleToken } = useAuthContext();

  const [web3, setWeb3] = useState<Web3>();
  const [triggerSignWallet, setTriggerSignWallet] = useState<boolean>(false);
  const [selectWallet, setSelectWallet] = useState<SelectWallet | undefined>();
  const [appError, setAppError] = useState<AppError | undefined>(undefined);
  const [joyIdAddress, setJoyIdAddress] = useState<string>('');

  const history = useHistory();
  const { disconnect } = useDisconnect();

  const { getNonce } = useGetNonce();
  const { getBindMessage } = useGetBindMessage();
  const { bindGoogle } = useBindGoogle();

  const deactivateWallet = async () => {
    if (account && isConnected) {
      disconnect();
      localStorage.clear();
    }
  };

  const handlePopupClose = () => {
    setPopup({
      opened: false,
    });
  };

  const updateRank = () => {
    setRankPurge((prev) => prev + 1);
  };

  const getChainName = () => {
    if (userAddress) return get(chainMapping, [chainId as number, 'name']) || 'eth';
    if (joyIdAddress) return 'polygon';
    return '';
  };

  const isMainnet = get(chainMapping, [chainId as number, 'network']) === 'mainnet';

  useInactiveListener();

  const duringDev = false;
  useEffect(() => {
    if (!userAddress) return;

    if (window?.MetaCRMTracking?.manualConnectWallet) {
      window.MetaCRMTracking.manualConnectWallet(userAddress);
    }
  }, [userAddress]);

  useEffect(() => {
    // evm login
    if (userAddress && walletProvider) {
      if (duringDev) {
        return;
      }
      const handleLogin = async () => {
        history.push('/');
        const network = getChainName();
        try {
          const provider = new ethers.providers.Web3Provider(walletProvider);
          const bindMessage = await getBindMessage({ address: userAddress });

          if (bindMessage?.domain && googleToken) {
            const s = await signTypedData(provider, userAddress, bindMessage);
            const b = await bindGoogle({ address: userAddress, signature: s, googleToken });
          }

          const nonceMessage = await getNonce({ network, address: userAddress });
          const signature = await signTypedData(provider, userAddress, nonceMessage);
          login({ network, address: userAddress, signature })
            .then(() => {
              setPopup({
                opened: true,
                title: 'Registered Successfully',
                description: 'Check the program guide below.',
              });
            })
            .finally(() => {
              setTriggerSignWallet(false);
            });
        } catch (e) {
          console.log(e);
          const error = e as AppError;
          Logger.error({ error });
          setAppError(error);
        }
      };
      handleLogin();
    }
  }, [walletProvider, userAddress, googleToken]);

  const contexts = {
    selectWallet,
    setSelectWallet,
    web3,
    currentChainType: getChainName(),
    isMainnet,
    triggerSignWallet,
    setTriggerSignWallet,
    appError,
    setAppError,
    deactivateWallet,
    joyIdAddress,
    setJoyIdAddress,
    referralCode,
    setReferralCode,
    rankPurge,
    updateRank,
    popup,
    setPopup,
    handlePopupClose,
  };

  return <ApplicationContext.Provider value={contexts}>{children}</ApplicationContext.Provider>;
};

const useApplicationContext = () => useContext(ApplicationContext);

const ApplicationProvider = Provider;

export { ApplicationProvider };

export default useApplicationContext;
