import styled from 'styled-components';
import { fontStyle } from '@/styles/common';

export const StyledContainer = styled.div`
  /* Your Mint styles here */
  width: 100%;
`;

export const StyledMint = styled.div`
  margin-bottom: 24px;
  .title {
    ${fontStyle('64px', '900')};
    margin-bottom: 24px;
  }
  .minted {
    font-style: italic;
    ${fontStyle('24px', 'normal')};
    margin-bottom: 24px;

    .bold {
      ${fontStyle('32px', '900')};
    }
  }
`;

export const StyledIntro = styled.div`
  padding: 24px;
  ${fontStyle('16px', 'normal')};
  border-radius: 32px;
  border: 6px solid;
  border-color: ${({ theme }) => theme.colors.shineYellow};

  .bold {
    ${fontStyle('16px', '900')};
  }

  .list {
    ${fontStyle('16px', 'normal')};
  }
`;

export const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 70px 0;
  background-color: ${({ theme }) => theme.colors.whited2};
`;

export const StyledIntro2 = styled.div`
  .title {
    ${fontStyle('32px', '900')};
    text-wrap: wrap;
    margin-bottom: 24px;
  }
  .list {
    ${fontStyle('16px', 'normal')};
    padding-inline-start: 14px;

    li {
      margin-bottom: 12px;
    }
  }
  .bold {
    ${fontStyle('16px', '900')};
  }

  .image {
    width: 100%;
  }
`;
