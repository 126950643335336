import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';

export const useInactiveListener = () => {
  const {
    connector: injected, active, error, activate, library,
  } = useWeb3React();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      library
      && library.provider
      && library.provider.on
      && !active
      && !error
      && library.provider.isMetaMask
    ) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injected);
      };
      const handleChainChanged = (chainId) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injected);
      };
      const handleAccountsChanged = (accounts) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injected);
        }
      };

      library.provider.on('connect', handleConnect);
      library.provider.on('chainChanged', handleChainChanged);
      library.provider.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (library.provider.removeListener) {
          library.provider.removeListener('connect', handleConnect);
          library.provider.removeListener('chainChanged', handleChainChanged);
          library.provider.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
  }, [active, error, activate, library, injected]);
};
