import React from 'react';
import { initConfig } from '@joyid/evm';
import { WalletProvider } from '@redreamerlab/uikit';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import 'normalize.css';
import { createRoot } from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import App from './App';
import './api/axios.config';
import { metaMask, metaMaskHooks, walletConnectV2, walletConnectV2Hooks } from './configs/evmConnecters';
import { availableChains, chains } from './configs/evmWalletConfigs';
import reportWebVitals from './reportWebVitals';

const isApplicationMainnet = (process.env.REACT_APP_NETWORK || 'TESTNET') === 'MAINNET';
const network = isApplicationMainnet ? 'mainnet' : 'testnet';
const mumbaiConfig = chains.find((c) => c.network === network && c.name === 'polygon');
const joyidAppURL = isApplicationMainnet ? 'https://app.joy.id' : 'https://testnet.joyid.dev';

initConfig({
  name: 'RE:DREAMER',
  logo: 'https://passport.redreamer.io/favicon.svg',
  network: {
    chainId: mumbaiConfig!.id,
    name: mumbaiConfig!.name,
  },
  rpcURL: mumbaiConfig!.rpc,
  joyidAppURL,
});

const connectors: any = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks],
];

const container = document.getElementById('root');
const root = createRoot(container!);

// 1. Your WalletConnect Cloud project ID
const projectId = '20ad84e95870b22fdd26dc91a4f892ab';

// 2. Set chains
const defaultChain = {
  chainId: mumbaiConfig?.id || 1,
  name: mumbaiConfig?.name || 'default',
  currency: 'Matic',
  explorerUrl: mumbaiConfig?.explorerUrl || 'https://etherscan.io',
  rpcUrl: mumbaiConfig?.rpc || 'https://polygon-rpc.com',
};

const walletModalChains = availableChains.map((chain) => {
  const { id, shortName, display, nativeCurrency, rpc, explorerUrl } = chain;
  return {
    chainId: id,
    name: display,
    currency: nativeCurrency?.symbol || shortName,
    explorerUrl: explorerUrl || 'https://etherscan.io',
    rpcUrl: rpc,
  };
});

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'localhost:3000', // origin must match your domain & subdomain
  icons: ['https://passport.domin.foundation/favicon.jpg'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: walletModalChains,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  allWallets: 'SHOW',
});

root.render(
  <WalletProvider connectors={connectors}>
    <App />
  </WalletProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
