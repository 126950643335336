import styled from 'styled-components';

const HEIGHT = 46;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${HEIGHT}px;
  width: 100%;
`;
export const StyledImage = styled.img`
  width: ${HEIGHT}px;
  height: ${HEIGHT}px;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const StyledCircle = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${HEIGHT}px;
  height: ${HEIGHT}px;
  border-radius: 50%;
  background-color: white;
  color: ${({ theme, disabled = false }) => (disabled ? theme.colors.whited2 : theme.colors.dodoPurple)};
  border: 1px solid ${({ theme, disabled }) => (disabled ? theme.colors.whited2 : theme.colors.dodoPurple)};
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  flex-shrink: 0;
`;

export const StyledLabel = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 900;
  margin: 0 12px;
  text-align: center;
`;
