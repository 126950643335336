import React from 'react';
import { ButtonType } from '@redreamerlab/uikit';
import { Button } from '@components/Button';
import { dodobirdDiagramImg, dominNetworkImg } from '@/styles/imgMapping';
import { StyledContainer } from './Styles';

const Diagram = () => {
  const handleButtonClick = () => {
    window.open('https://www.domin.foundation/', '_blank', 'noopener');
  };
  return (
    <StyledContainer>
      <div className="first">
        <div className="title">
          NEW ERA OF
          <br />
          COMMERCE
          <br />
          = dApps + Apps
        </div>
        <div className="learn">
          <img className="domin-network-img" alt="domin network" src={dominNetworkImg} />
          <div className="description">
            Hybrid Infrastructure for Commerce
            <br />
            in On-chain Economies
          </div>
          <Button onClick={handleButtonClick} type="button" buttonType={ButtonType.GENERAL}>
            Learn More
          </Button>
        </div>
      </div>
      <div className="image-container">
        <img className="image" alt="diagram" src={dodobirdDiagramImg} />
      </div>
    </StyledContainer>
  );
};

export default Diagram;
