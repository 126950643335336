import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;

  &:not(:last-child) {
    margin-right: 24px;
  }
`;

export const StyledWormImage = styled.img<{ isSelected: boolean }>`
  width: 100px;
  height: 100px;
  border: 2px solid ${({ theme }) => theme.colors.whited2};
  border-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.dodoPurple : theme.colors.whited2)};
  border-radius: 16px;

  @media ${XS_QUERY} {
    width: 75px;
    height: 75px;
  }
`;

export const StyledWormCount = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  font-size: 16px;
  border-radius: 50%;
  color: white;
  background-color: black;
  transform: translate(50%, -50%);
  z-index: 1;

  @media ${XS_QUERY} {
    width: 25px;
    height: 25px;
  }
`;
