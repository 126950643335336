import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routeConfigs from './config';

const Router = () => (
  <Switch>
    {routeConfigs
      .filter((o) => !o.hidden)
      .map((route) => (
        <Route key={route.id} exact path={route.path}>
          <route.component auth={route.auth} />
        </Route>
      ))}
    <Redirect to="/" />
  </Switch>
);

export { routeConfigs };
export default Router;
