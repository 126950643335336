import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  border-radius: 32px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 7px 1px;
  justify-content: space-between;
  background-color: white;

  &:not(last-child) {
    margin-bottom: 24px;
  }
`;

export const StyledCount = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  width: 150px;
  font-weight: 900;
  font-size: 24px;
  padding: 24px;
  border-radius: 32px 0 0 32px;
  background: ${({ color }) => color || 'linear-gradient(rgb(255, 199, 0.7), rgb(230, 152, 0.7), rgb(255, 214, 0.7))'};

  @media ${XS_QUERY} {
    width: 100px;
    flex-direction: column;
    justify-content: center;
  }

  .label {
    color: white;
  }

  .bug {
    display: flex;
    align-items: center;
    .bug-image {
      margin-left: 12px;
      width: 32px;
      height: 32px;

      border-radius: 32px;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px;

  @media ${XS_QUERY} {
    flex-direction: column;
    align-items: start;
  }

  .text {
    width: 66%;
    padding: 0 12px;

    @media ${XS_QUERY} {
      margin-bottom: 12px;
      width: 100%;
      padding: 12px 0;
    }

    .title {
      font-weight: 900;
      font-size: 16px;
      margin-bottom: 12px;
    }

    .sub-title {
      font-size: 12px;
    }

    .big-title {
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 12px;

      @media ${XS_QUERY} {
        font-size: 20px;
      }
    }

    .description {
      font-size: 14px;
      margin-top: 12px;
    }

    .chain {
      font-weight: 900;
      font-size: 16px;
    }
  }

  .button-container {
    min-width: 200px;
    width: fit-content;

    @media ${XS_QUERY} {
      min-width: 100px;
      width: 100%;
    }
  }
`;
