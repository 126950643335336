export enum Status {
  ACTIVE = 'active',
  END = 'ended',
}

export type Props = {
  title: string;
  status?: Status;
  startDate?: string;
  endDate?: string;
  link?: string;
  color?: string;
};
