import { Method } from '../promise-request';

const caller = (data) => {
  const version = 'v1';
  const url = `/${version}/feed/approvalForAll`;
  return ({
    method: Method.GET,
    url,
  });
};

export default caller;
