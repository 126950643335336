import React from 'react';
import { ButtonType } from '@redreamerlab/uikit';
import { Button } from '@components/Button';
import { Row, StyledIconImg } from '@styles/common';
import { ghostPartyIcon, successModalIcon } from '@styles/imgMapping';
import { StyledMessage, StyledModal, StyledTitle } from './Styles';

type Props = {
  title: string;
  opened: boolean;
  onClose: () => void;
  message: string;
  showIcon?: boolean;
};

const SuccessModal = ({ title, opened, onClose, message, showIcon = true }: Props) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <StyledModal hasBackDrop opened={opened} onClose={handleClose} width="450" disabledCloseButton>
      <Row direction="column" align="center">
        <StyledIconImg src={successModalIcon} alt="success" width="40px" />
        <br />
        <div>
          <StyledTitle>{title}</StyledTitle>
          <StyledMessage>{message}</StyledMessage>
        </div>
      </Row>
      <br />
      <Row justify="center">
        <Button onClick={handleClose} type="button" buttonType={ButtonType.GENERAL}>
          Ok
        </Button>
      </Row>
    </StyledModal>
  );
};

export default SuccessModal;
